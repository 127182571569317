import RenderRouter from '@/routes';
import { NavigationMenu, Provider } from '@shopify/app-bridge-react';
import { NavigationLink } from '@shopify/app-bridge-react/components/NavigationMenu/NavigationMenu';
import { AppProvider, Frame, SkeletonPage } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { useEffect, useMemo, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { images } from './asset';
import SpecialOffer from './components/SpecialOffer';
import Toast from './components/common/Toast';
import { config } from './config';
import customersList from './constants/customerHidePayment';
import { PATH } from './constants/path';
import UserPlans from './hooks/userPlans';
import { apiCaller } from './redux/query';
import slice from './redux/slice';
import { handleShowSpecialOffer, showSpecialOfferSelector } from './redux/slice/local.slice';
import { isDevelopmentPlan } from './helpers';
import { UserPlan } from './constants/enum';

const navigationLinks = [
  {
    label: 'Visitor Analytics',
    destination: PATH.ANALYTICS.pathname,
  },
  {
    label: 'Block & Re-director',
    destination: PATH.BLOCKLIST.pathname,
  },
  {
    label: 'Fraud orders analytics',
    destination: PATH.FRAUD_ORDER.pathname,
  },
  {
    label: 'Block checkout',
    destination: PATH.BLOCKED_CHECKOUT.pathname,
  },
  customersList.includes(config.shop)
    ? {
      label: 'Checkout Rules',
      destination: PATH.CHECKOUT_RULES.pathname,
    }
    : undefined,
  {
    label: 'Settings',
    destination: PATH.SETTINGS.pathname,
  },
  {
    label: 'Feedback',
    destination: PATH.FEEDBACK.pathname,
  },
  {
    label: 'Pricing Plan',
    destination: PATH.PRICING_PLAN.pathname,
  },
];

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery(config?.shop);

  const history = useMemo(() => ({ replace: (path: string) => navigate(path, { replace: true }) }), [navigate]);

  const configApp = {
    apiKey: process.env.REACT_APP_APP_ID || '',
    host: new URLSearchParams(location.search).get('host') || '',
    forceRedirect: process.env.REACT_APP_ENV !== 'dev',
  };

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history],
  );

  useEffect(() => {
    if (config && config.role !== 'admin') {
      mixpanel?.init(`${process.env.REACT_APP_MIXPANEL_ID}`);
      // Set this to a unique identifier for the user performing the event.
      mixpanel?.identify(`${config.shop}`);
      const timer = setTimeout(() => {
        var crisp = document.createElement('script');
        crisp.setAttribute('src', `${process.env.REACT_APP_API_END_POINT}file/crisp?shop=${config.shop}`);
        crisp.setAttribute('id', 'blockify-shopify-crisp');
        crisp.appendChild(document.createTextNode(``));
        hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID), 6);
        document.body.appendChild(crisp);
      }, 2500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  useEffect(() => {
    if (fetchDataSettings.data) {
      dispatch(
        slice.dataSetting.actions.handleSettings({
          settings: fetchDataSettings.data.settings,
          url: fetchDataSettings.data.url || '',
          summaryStats: fetchDataSettings.data.summaryStats,
          quoteAppInstalled: fetchDataSettings.data.quoteAppInstalled || false,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.data]);

  useEffect(() => {
    dispatch(slice.dataSetting.actions.handleLoading(fetchDataSettings.isFetching));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.isFetching]);

  const [showCampaignFromToDate, setShowCampaignFromToDate] = useState(false);

  useEffect(() => {
    const currentDate = dayjs();
    const campaignStartDate = dayjs(1722445199000); //2024-07-31T23:59:59+07:00
    const campaignEndDate = dayjs(1726851599999); //2024-09-20T23:59:59+07:00
    const lastInstalledDate = dayjs(
      fetchDataSettings.data?.settings.user.lastInstalledDate || fetchDataSettings.data?.settings.user.installedDate,
    );

    if (lastInstalledDate.isBefore(campaignStartDate) && currentDate.isBefore(campaignEndDate)) {
      setShowCampaignFromToDate(true);
    } else {
      setShowCampaignFromToDate(false);
    }
  }, [fetchDataSettings.data?.settings.user.installedDate, fetchDataSettings.data?.settings.user.lastInstalledDate]);

  const showSpecialOffer = useSelector(showSpecialOfferSelector);
  const { userPlanFree, userPlanEnterprise, shopifyPlanPlus, planAppliedList } = UserPlans();

  const isShowSpecialOffer =
    !userPlanEnterprise &&
    !shopifyPlanPlus &&
    showSpecialOffer &&
    showCampaignFromToDate &&
    location.pathname !== PATH.WELCOME.pathname &&
    !planAppliedList?.length;

  const businessName = fetchDataSettings.data?.settings.user.businessName;

  const renderSpecialOffer = (
    <SpecialOffer
      image={images.BlockifyFraudCountryBlocker}
      heading="You’ve Got Unlimited Blockify Top-tier Plans for $1"
      description={`Congratulations ${businessName}! ✨ To celebrate our 7th anniversary, we're offering the first 100 users
      1 month of our Blockify ${userPlanFree ? 'Premium & Enterprise' : 'Enterprise'} plan for just $1.`}
      labelPrimaryBtn="🎁 Unbox the gift now"
      labelSecondaryBtn="Maybe later"
      onClickPrimaryBtn={() => {
        dispatch(handleShowSpecialOffer(false));
        navigate(PATH.PRICING_PLAN);
      }}
      onClickSecondaryBtn={() => dispatch(handleShowSpecialOffer(false))}
    />
  );

  return (
    <AppProvider i18n={enTranslations}>
      {config.embedded !== '1' ? (
        <Frame>
          <Toast />
          {fetchDataSettings.isLoading ? <SkeletonPage /> : isShowSpecialOffer ? renderSpecialOffer : <RenderRouter />}
        </Frame>
      ) : (
        <Provider config={configApp} router={router}>
          {fetchDataSettings &&
            fetchDataSettings.data?.settings.user.plan === UserPlan.FREE &&
            isDevelopmentPlan(fetchDataSettings.data?.settings.shopifyPlan) ? (
            <NavigationMenu navigationLinks={[]} matcher={(link, _) => link.destination === location.pathname.split('/')[1]} />
          ) : (
            <NavigationMenu
              navigationLinks={navigationLinks.filter((item) => item !== undefined) as NavigationLink[]}
              matcher={(link, _) => link.destination === location.pathname.split('/')[1]}
            />
          )}

          <Frame>
            <Toast />
            {fetchDataSettings.isLoading ? <SkeletonPage /> : isShowSpecialOffer ? renderSpecialOffer : <RenderRouter />}
          </Frame>
        </Provider>
      )}
    </AppProvider>
  );
}

export default App;
