import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
// Define the initial state using that type
const initialState = {
  eventGa4RecommendedApp: false,
  eventGa4RecommendedAppSlick: false,
  eventGa4Banner: false,
};

const ga4Slice = createSlice({
  name: 'eventGa4',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleBannerEventGa4: (state, action: PayloadAction<boolean>) => {
      state.eventGa4Banner = action.payload;
    },
    handleEventGa4RecommendedApp: (state, action: PayloadAction<boolean>) => {
      state.eventGa4RecommendedApp = action.payload;
    },
    handleEventGa4RecommendedAppSlick: (state, action: PayloadAction<boolean>) => {
      state.eventGa4RecommendedAppSlick = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const eventGa4RecommendedAppSelector = createSelector(
  (state: RootState) => state.eventGa4,
  (state) => state.eventGa4RecommendedApp,
);
export const eventGa4RecommendedAppSlickSelector = createSelector(
  (state: RootState) => state.eventGa4,
  (state) => state.eventGa4RecommendedAppSlick,
);
export const eventGa4BannerSelector = createSelector(
  (state: RootState) => state.eventGa4,
  (state) => state.eventGa4Banner,
);

export default ga4Slice;
