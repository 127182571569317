import Layout from '@/components/layout';
import { Ga4Event } from '@/constants/enum';
import { useGa4 } from '@/hooks/useGa4';
import userPlans from '@/hooks/userPlans';
import { gaFourSelector } from '@/redux/slice/session.slice';
import { LegacyStack } from '@shopify/polaris';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import TablePricingPlan from './components/TablePricingPlan';

function PricingPlan() {
  const { newUser, userPlanShopifyPlus } = userPlans();
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();

  if (!gaFour.pricingPlan) {
    handleGa4(Ga4Event.PricingPlan);
  }

  return (
    <Layout
      layoutProps={{
        title: 'Pricing plan',
        fullWidth: !newUser && userPlanShopifyPlus,
      }}
    >
      <LegacyStack vertical spacing="loose">
        <TablePricingPlan />
      </LegacyStack>
    </Layout>
  );
}

export default memo(PricingPlan);
