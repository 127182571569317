/* eslint-disable jsx-a11y/anchor-is-valid */
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { useGa4 } from '@/hooks/useGa4';
import slice from '@/redux/slice';
import { gaFourSelector, showRecommendedAppsSelector } from '@/redux/slice/session.slice';
import { IGA4 } from '@/types/components';
import { Button, Card, InlineGrid } from '@shopify/polaris';
import { CancelMinor } from '@shopify/polaris-icons';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RightRecommendApps from './Slide';
import { leftRecommendApp } from './configRecommend';
import { RecommendedAppsStyled } from './styled';

const RecommendedApps = () => {
  const dispatch = useDispatch();
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  const showRecommendedApps = useSelector(showRecommendedAppsSelector);
  const handleTurnOffRecommendedApp = useCallback(() => {
    dispatch(slice.session.actions.handleShowRecommendedApps(false));
  }, [dispatch]);

  const handleClick = useCallback(
    (item: IGA4) => () => {
      window.open(item.href, '_blank', 'noopener,noreferrer');
      if (!gaFour[item.key as keyof typeof gaFour]) {
        handleGa4(item.clickGA);
      }
    },
    [gaFour, handleGa4],
  );

  return (
    <>
      {showRecommendedApps ? (
        <RecommendedAppsStyled>
          <div className="btn-cancel">
            <Button variant="plain" icon={CancelMinor} onClick={handleTurnOffRecommendedApp} />
          </div>
          <BoldText>Recommended apps</BoldText>
          <div className="mt-16">
            <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={'400'}>
              <div className="main-recommend-app" id="banner-2">
                <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} gap={'400'}>
                  {leftRecommendApp.map((item) => {
                    return (
                      <Card padding={'400'} key={item.title}>
                        <div className="recommend-app-container">
                          <div className="recommend-app-content">
                            <img src={item.logo} alt={item.title} />
                            <div>
                              <BoldText>{item.title}</BoldText>
                            </div>
                            <RegularText>{item.description}</RegularText>
                          </div>
                          <Button onClick={handleClick(item)}>Check now</Button>
                        </div>
                      </Card>
                    );
                  })}
                </InlineGrid>
              </div>
              <RightRecommendApps />
            </InlineGrid>
          </div>
        </RecommendedAppsStyled>
      ) : null}
    </>
  );
};

export default memo(RecommendedApps);
