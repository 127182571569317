import { Enum } from '@/constants';
import { getSomeDaysAgo } from '@/helpers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IndexFiltersMode } from '@shopify/polaris';

export interface VisitorsDetail {
  startDate: Date;
  endDate: Date;
  page: number;
  perPage: string;
  sortBy: string;
  sort: string;
}

export interface ListVisitors {
  search?: string;
  status?: string;
  risk?: string;
  page: number;
  type?: string;
  sortBy?: string;
  sort: string;
  countriesCode?: Array<string>;
  providers?: Array<string>;
  Locations?: Array<string>;
}
export interface ListAllAccess {
  search?: string;
  status?: string;
  risk?: string;
  page: number;
  type?: string;
  startDate: Date;
  endDate: Date;
  sortBy?: string;
  sort: string;
  countriesCode?: Array<string>;
  providers?: Array<string>;
  Locations?: Array<string>;
}
export interface AllAccessDetail {
  page: number;
  perPage: string;
  type?: string;
  sortBy?: string;
  sort: string;
  startDate: Date;
  endDate: Date;
}

const now = new Date();
// Define the initial state using that type
const initialState: {
  tabsTable: number;
  visitorLogTable: ListVisitors;
  visitorDetail: VisitorsDetail;
  inputSearch: string;
  titleBtnDatePicker: string;
  titleBtnDatePickerVisitorsDetail: string;
  allAccessTable: ListAllAccess;
  allAccessDetail: AllAccessDetail;
  perPage: string;
  inputSearchAllAccess: string;
  modeVisitorLogTable: IndexFiltersMode;
} = {
  modeVisitorLogTable: IndexFiltersMode.Default,
  tabsTable: 0,
  visitorLogTable: {
    search: '',
    status: '',
    risk: undefined,
    type: undefined,
    countriesCode: [],
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'lastSeenOn',
  },
  visitorDetail: {
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    perPage: '10',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'accessAt',
  },
  inputSearchAllAccess: '',
  inputSearch: '',
  titleBtnDatePicker: 'Last 7 days',
  titleBtnDatePickerVisitorsDetail: 'Last 7 days',
  allAccessTable: {
    search: undefined,
    status: undefined,
    risk: undefined,
    type: undefined,
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'lastSeenOn',
  },

  allAccessDetail: {
    perPage: '10',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: Enum.SortByType.ACCESS_AT,
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
  },
  perPage: '10',
};

export const visitorLogSlice = createSlice({
  name: 'visitorLog',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleVisitorLogTable: (state, action: PayloadAction<ListVisitors>) => {
      state.visitorLogTable = action.payload;
    },
    handleVisitorsDetailTable: (state, action: PayloadAction<VisitorsDetail>) => {
      state.visitorDetail = action.payload;
    },

    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
    handleTitleBtnDatePickerVisitorsDetail: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePickerVisitorsDetail = action.payload;
    },
    handleTableTab: (state, action: PayloadAction<number>) => {
      state.tabsTable = action.payload;
    },
    handleAllAccessTable: (state, action: PayloadAction<ListAllAccess>) => {
      state.allAccessTable = action.payload;
    },
    handleAllAccessDetail: (state, action: PayloadAction<AllAccessDetail>) => {
      state.allAccessDetail = action.payload;
    },
    handlePerPage: (state, action: PayloadAction<string>) => {
      state.perPage = action.payload;
    },
    handleInputSearchAllAccess: (state, action: PayloadAction<string>) => {
      state.inputSearchAllAccess = action.payload;
    },
    handleMode: (state, action: PayloadAction<IndexFiltersMode>) => {
      state.modeVisitorLogTable = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const tabsTableSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.tabsTable,
);
export const visitorLogSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.visitorLogTable,
);
export const allAccessDetailSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.allAccessDetail,
);
export const allAccessSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.allAccessTable,
);
export const visitorDetailSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.visitorDetail,
);
export const inputSearchSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.inputSearch,
);

export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.titleBtnDatePicker,
);
export const titleBtnDatePickerVisitorsDetailSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.titleBtnDatePickerVisitorsDetail,
);
export const perPageSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.perPage,
);
export const inputSearchAllAccessSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.inputSearchAllAccess,
);
export const modeVisitorLogTableSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.modeVisitorLogTable,
);

export default visitorLogSlice;
