import { useState, useCallback } from 'react';

function useToggle<S>(initialValue: S): [S, () => void] {
  const [value, setValue] = useState<S>(initialValue);
  const toggle = useCallback(() => {
    setValue((prev) => !prev as S);
  }, []);

  return [value, toggle];
}

export default useToggle;