import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';
interface Props {
  isSynctrackBanner?: boolean;
}

export const BannerTemplateStyled = styled.div<Props>`
  position: relative;
  .btn-cancel {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 511;
  }
  .banner-template-left {
    display: flex;
    align-items: center;
  }
  .banner-img {
    width: 100%;
    min-height: 165px;
    cursor: ${(props) => props.isSynctrackBanner ? 'pointer' : ''}
  }
  .banner-template-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pd-16 {
    padding: 1rem;
  }
  @media screen and (max-width: ${BREAKPOINT.MD}px) {
    .banner-img {
      width: 105%;
      min-height: 100%;
    }
  }
  @media screen and (max-width: ${BREAKPOINT.SM}px) {
    .banner-img {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }
  }
`;
