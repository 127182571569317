import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { Enum, PATH } from '@/constants';
import { urlRickScore } from '@/constants/link';
import { dateToTimeStamp, findIPRange, formatDate, secondToTime } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import visitorLogSlice, {
  allAccessSelector,
  perPageSelector,
  visitorDetailSelector,
  visitorLogSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import {
  Badge,
  BadgeProps,
  Icon,
  IndexTable,
  Link,
  Modal,
  Pagination,
  SkeletonBodyText,
  Text,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { CircleDisableMinor, DuplicateMinor } from '@shopify/polaris-icons';
import mixpanel from 'mixpanel-browser';
import { memo, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const badgeRiskConfig: Array<{ score: Array<number>; tone: BadgeProps['tone']; tooltip: string }> = [
  {
    score: [-1, Enum.RiskLevelScore.Safe],
    tone: 'success',
    tooltip: 'Low risk IP',
  },
  {
    score: [Enum.RiskLevelScore.Safe, Enum.RiskLevelScore.Risky],
    tone: 'warning',
    tooltip: 'High risk IP',
  },
  {
    score: [Enum.RiskLevelScore.Risky, Enum.RiskLevelScore.Dangerous],
    tone: 'critical',
    tooltip: 'Dangerous IP',
  },
];

const TableVisitorLog = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCountry = useCountry();

  const visitorLogTable = useSelector(visitorLogSelector);
  const visitorDetail = useSelector(visitorDetailSelector);
  const perPage = useSelector(perPageSelector);
  const allAccess = useSelector(allAccessSelector);

  const { userPlanFree } = userPlans();

  const [confirmBlock, setConfirmBlock] = useState({
    status: false,
    ip: '',
  });
  const [state, setState] = useState({
    copied: false,
  });

  const [addBlackList, { isLoading }] = apiCaller.useUpsertRuleMutation();
  const { data, isFetching } = apiCaller.useFetchVisitorListQuery({
    ...visitorLogTable,
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    perPage,
  });

  const resourceName = {
    singular: 'visitor',
    plural: 'visitors',
  };
  const items = useMemo(() => {
    return data?.listIp?.map((visitorLogItem) => {
      const handleCopyIPAdress = () => {
        navigator.clipboard.writeText(visitorLogItem.ip || '').then(() => {
          setState({ ...state, copied: true });
          dispatch(
            slice.toastSlice.actions.handleToast({
              isOpen: true,
              content: 'Copied',
              error: false,
            }),
          );
        });
      };
      const riskScore = visitorLogItem.risk;
      const isVpn: boolean = visitorLogItem.isVpn;

      let badgeRisk;
      if (riskScore === null) {
        badgeRisk = undefined;
      } else {
        badgeRisk = badgeRiskConfig.find((item) => {
          return item.score[0] < riskScore && riskScore <= item.score[1];
        });
      }

      return {
        id: visitorLogItem.ip,
        ipBlocked: (
          <span className="d-flex address-container">
            <RegularText>
              {visitorLogItem.ip}
              <br />
              {visitorLogItem.ipv4 ? (
                <>
                  <Text variant="bodySm" as="span">
                    IPv4: {visitorLogItem.ipv4}
                  </Text>
                  <br />
                </>
              ) : null}
              {findIPRange(visitorLogItem.ip) !== null ? (
                <Text variant="bodySm" as="span">
                  {`IP range: ${findIPRange(visitorLogItem.ip)}`}
                </Text>
              ) : null}
            </RegularText>
            <span className="ml-8 pointer">
              <Tooltip content="Copy">
                <span
                  className="btn-copy"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyIPAdress();
                  }}
                >
                  <Icon source={DuplicateMinor} />
                </span>
              </Tooltip>
            </span>
          </span>
        ),
        country: (
          <Tooltip dismissOnMouseOut content={handleCountry.renderCountry(visitorLogItem.countryCode)}>
            <ReactCountryFlag svg className="emojiFlag" countryCode={visitorLogItem.countryCode} />
          </Tooltip>
        ),
        city: (
          <RegularText>{[visitorLogItem.regionName, visitorLogItem.cityName].filter((item) => !!item).join(' - ')}</RegularText>
        ),
        totalAccess: (
          <p style={{ width: '4rem', textAlign: 'end' }}>
            <RegularText>
              <Link
                removeUnderline
                onClick={() => {
                  dispatch(
                    slice.visitorAnalyticsSlice.actions.handleVisitorsDetailTable({
                      ...visitorDetail,
                      perPage: '10',
                      page: 1,
                      sort: Enum.SortType.DESC,
                      sortBy: 'accessAt',
                    }),
                  );
                  navigate({
                    ...PATH.VISITORS_DETAIL,
                    pathname: `/analytics/visitor/${visitorLogItem.ip}`,
                  });
                }}
              >
                {visitorLogItem.totalAccess}
              </Link>
            </RegularText>
          </p>
        ),
        averageDuration: <RegularText>{secondToTime(Math.ceil(visitorLogItem?.averageDuration))}</RegularText>,
        lastSeenOn: <RegularText>{formatDate(visitorLogItem.lastSeenOn)}</RegularText>,
        ispName: userPlanFree ? '---' : <RegularText>{visitorLogItem.provider || '---'}</RegularText>,
        zipCode: <RegularText>{visitorLogItem.zipCode || '---'}</RegularText>,
        risk:
          <RegularText>
            {userPlanFree ? (
              <div
                className="pointer text-decoration"
                onClick={() => {
                  navigate(PATH.PRICING_PLAN);
                  mixpanel?.track('Analytics_needs_upgrade');
                }}
              >
                <Badge tone="warning">Needs upgrade</Badge>
              </div>
            ) : (
              <Tooltip content={badgeRisk?.tooltip}>
                <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
              </Tooltip>
            )}
          </RegularText>,
        vpnStatus: userPlanFree ? (
          <Tooltip content="Available on higher plan">---</Tooltip>
        ) : (
          <Badge tone={isVpn ? 'warning' : 'success'}>{isVpn ? 'Yes' : 'No'}</Badge>
        ),
        status:
          visitorLogItem.status === 'allow' ? (
            <Badge tone="success">{visitorLogItem.status.charAt(0).toUpperCase() + visitorLogItem.status.slice(1)}</Badge>
          ) : (
            <Badge tone="critical">{visitorLogItem.status.charAt(0).toUpperCase() + visitorLogItem.status.slice(1)}</Badge>
          ),
        action:
          visitorLogItem.status === 'block' ? (
            <Tooltip dismissOnMouseOut content="Blocked">
              <div className="btn-disable">
                <Icon tone="subdued" source={CircleDisableMinor} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip dismissOnMouseOut content="Add to the blacklist">
              <div
                className="btn-disable pointer"
                onClick={(e) => {
                  setConfirmBlock({
                    status: true,
                    ip: visitorLogItem.ip,
                  });
                }}
              >
                <Icon tone="critical" source={CircleDisableMinor} />
              </div>
            </Tooltip>
          ),
      };
    });
  }, [data?.listIp, handleCountry, userPlanFree, state, dispatch, visitorDetail, navigate]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items || []);

  const promotedBulkActions = [
    {
      content: 'Cancel',
      onAction: () => clearSelection(),
    },
    {
      content: 'Add IP to blacklist',
      onAction: () =>
        setConfirmBlock({
          status: true,
          ip: '',
        }),
    },
  ];

  const rowMarkup = useMemo(() => {
    return items?.map(
      (
        { id, ipBlocked, action, city, country, lastSeenOn, risk, status, totalAccess, ispName, vpnStatus, averageDuration },
        index,
      ) => (
        <IndexTable.Row selected={selectedResources.includes(id)} id={id} key={id} position={index}>
          <IndexTable.Cell>{ipBlocked}</IndexTable.Cell>
          <IndexTable.Cell>{country}</IndexTable.Cell>
          <IndexTable.Cell>{city}</IndexTable.Cell>
          <IndexTable.Cell>{totalAccess}</IndexTable.Cell>
          <IndexTable.Cell>{averageDuration}</IndexTable.Cell>
          <IndexTable.Cell>{lastSeenOn}</IndexTable.Cell>
          <IndexTable.Cell>{ispName}</IndexTable.Cell>
          <IndexTable.Cell id="risk-score">{risk}</IndexTable.Cell>
          <IndexTable.Cell id="vpn-status">{vpnStatus}</IndexTable.Cell>
          <IndexTable.Cell>{status}</IndexTable.Cell>
          <IndexTable.Cell>{action}</IndexTable.Cell>
        </IndexTable.Row>
      ),
    );
  }, [items, selectedResources]);

  const handleAddIps = async () => {
    try {
      const res: any = await addBlackList({
        type: Enum.ActionType.Block,
        criteria: Enum.CriteriaType.IpAddress,
        ipAddress: confirmBlock.ip ? confirmBlock.ip : selectedResources,
        priority: Enum.ListType.BlackList,
        isActive: true,
      });

      dispatch(
        slice.toastSlice.actions.handleToast({
          isOpen: true,
          content: res?.data?.msg || 'Failed',
          error: false,
        }),
      );
      clearSelection();
      setConfirmBlock({ ip: '', status: false });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>IP address</RegularText>, id: '1' },
          { title: <RegularText>Country</RegularText>, id: '2' },
          { title: <RegularText>State/Province/City</RegularText>, id: '3' },
          { title: <RegularText>Total access</RegularText>, id: '4' },
          { title: <RegularText>Average Durations</RegularText>, id: '5' },
          {
            title: <RegularText>Last seen on</RegularText>,
            id: '6',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>Internet provider</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '7',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>Risk score</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: 'risk-score-header',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>VPN/Proxy</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: 'vpn-header',
          },
          { title: <RegularText>Status</RegularText>, id: '8' },
          { title: <RegularText>Action</RegularText>, id: '9' },
        ]}
        selectable={!userPlanFree}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={promotedBulkActions}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>

      <div className="mt-16 mb-16 table-pagination">
        {data && data?.meta.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta.totalItems
                ? `Showing ${(visitorLogTable.page - 1) * Number(perPage) + 1} to ${Math.min(
                  visitorLogTable.page * Number(perPage),
                  data?.meta.totalItems,
                )} of ${data?.meta.totalItems} visitors`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogTable({
                  ...visitorLogTable,
                  page: visitorLogTable.page - 1,
                }),
              );
            }}
            hasNext={data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(perPage)) && !isFetching}
            onNext={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogTable({
                  ...visitorLogTable,
                  page: visitorLogTable.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>

      <Modal
        onClose={() => setConfirmBlock({ ip: '', status: false })}
        open={confirmBlock.status}
        title="Block IP"
        sectioned
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setConfirmBlock({ ip: '', status: false }),
          },
        ]}
        primaryAction={{
          loading: isLoading,
          content: 'Block',
          onAction: handleAddIps,
        }}
      >
        <RegularText>This IP will be added to the blacklist for blocking. You can remove it anytime.</RegularText>
      </Modal>
    </div>
  );
};

export default memo(TableVisitorLog);
