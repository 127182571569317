import { dashboardImage } from '@/asset/images/dashboard';
import promote from '@/asset/images/promote.png';
import BannerLimitRules from '@/components/BannerLimitRules';
import BannerReviewApp from '@/components/BannerReviewApp';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import CheckoutRulesBanner from '@/components/CheckoutRulesBanner';
import FlashSaleCard from '@/components/FlashSaleCard';
import CustomLayout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { PATH } from '@/constants';
import { ActionType, BREAKPOINT, CriteriaType, Ga4Event, ListType } from '@/constants/enum';
import { link } from '@/constants/link';
import { useGa4 } from '@/hooks/useGa4';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import bannerSlice, {
  bannerNoticeEmbeddedAppSelector,
  bannerNoticeFreePlanSelector, showFlashSaleCardSelector
} from '@/redux/slice/banner.slice';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import sessionSlice, { gaFourSelector } from '@/redux/slice/session.slice';
import settingsSlice from '@/redux/slice/settings.slice';
import { Banner, Button, Card, Collapsible, InlineGrid, Text } from '@shopify/polaris';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import RecommendedApps from './recommendedApps';
import { HomePageStyled } from './styled';

function HomePage() {
  const recommendAppRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userPlanFree, userPlanEnterprise, shopifyPlanPlus, planAppliedList } = UserPlans();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const isTablet = useMediaQuery({ maxWidth: BREAKPOINT.MD });
  const bannerNoticeEmbeddedApp = useSelector(bannerNoticeEmbeddedAppSelector);
  const bannerNoticeFreePlan = useSelector(bannerNoticeFreePlanSelector);
  const showFlashSaleCard = useSelector(showFlashSaleCardSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const blockRuleSelected = useSelector(settingSelector);
  const overview = apiCaller.useOverviewSettingsQuery().data?.overview;
  const { handleGa4 } = useGa4();
  const gaFour = useSelector(gaFourSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery();
  const [trackAction] = apiCaller.useTrackActionMutation();
  const [showBannerFromToDate, setShowBannerFromToDate] = useState(true);
  const isShowBannerCampaign =
    !userPlanEnterprise && !shopifyPlanPlus && showFlashSaleCard && showBannerFromToDate && !planAppliedList?.length;

  useEffect(() => {
    const currentDate = dayjs();
    const campaignStartDate = dayjs(1722445199000); //2024-07-31T23:59:59+07:00
    const campaignEndDate = dayjs(1726851599999); //2024-09-20T23:59:59+07:00
    const lastInstalledDate = dayjs(dataSettings?.settings.user.lastInstalledDate || dataSettings?.settings.user.installedDate);

    if (lastInstalledDate.isBefore(campaignStartDate) && currentDate.isBefore(campaignEndDate)) {
      setShowBannerFromToDate(true);
    } else {
      setShowBannerFromToDate(false);
    }
  }, [dataSettings?.settings.user.installedDate, dataSettings?.settings.user.lastInstalledDate]);

  const configMostPopularRules = useMemo(() => {
    return [
      {
        title: 'Block IP address',
        description: 'Protect your store by blocking unwanted IP addresses.',
        onAction: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              criteria: CriteriaType.IpAddress,
              type: ActionType.Block,
              priority: ListType.BlackList,
            }),
          );
          mixpanel?.track('Quick_set_up_block_ip_address');
        },
      },
      {
        title: 'Redirect IP address ',
        description: 'Save your store by setting up a redirect to another URL.',
        onAction: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              criteria: CriteriaType.IpAddress,
              type: ActionType.Redirect,
              priority: ListType.BlackList,
            }),
          );
          mixpanel?.track('Quick_set_up_redirect_ip_address');
        },
      },
      {
        title: 'Block email on checkout',
        description: 'Block unwanted email addresses.',
        onAction: () => {
          navigate(PATH.BLOCKED_CHECKOUT);
          mixpanel?.track('Quick_set_up_block_email_checkout');
        },
      },
      {
        title: 'Whitelist location',
        description: 'Allow specific locations to access your store without being affected by blocking rule.',
        onAction: () => {
          navigate(PATH.BLOCK_PAGE);
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              criteria: CriteriaType.Country,
              priority: ListType.WhiteList,
            }),
          );
          mixpanel?.track('Quick_set_up_whitelist_location');
        },
      },
      {
        title: 'Auto-block visitors',
        description: 'Prevent visitors visit to your store.',
        onAction: () => {
          navigate(PATH.SETTINGS);
          dispatch(settingsSlice.actions.handleSelected(['blocker']));
          mixpanel?.track('Quick_set_up_auto_block');
        },
      },
    ];
  }, [navigate, dispatch, blockRuleSelected]);



  const handleLearnMore = () => {
    trackAction('read_docs');
    window.open('https://docs.ipblocker.io/', '_blank');
    mixpanel?.track('Get_docs');
  };

  const handleLearnMoreSynctrack = () => {
    trackAction('read_docs');
    window.open('https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp', '_blank');
    mixpanel?.track('Read_help_desk');
    if (!gaFour.synctrack) {
      handleGa4(Ga4Event.SynctrackClick);
    }
  };

  const sendMessage = () => {
    const sendText = 'I need assistance with using Blockify';
    try {
      $crisp.push(['set', 'session:event', ['ReviewStoreBlockify']]);
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
      dispatch(slice.banner.actions.handleShowBannerReviewSetUpApp(false));
    } catch (err) {
      console.log(err);
    }
    mixpanel?.track('Get_support_from_agent');
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour.homePage) {
            dispatch(
              sessionSlice.actions.handleGaFour({
                key: 'homePage',
              }),
            );
            handleGa4(Ga4Event.CheckoutRulesImpression);
            handleGa4(Ga4Event.SynctrackImpression);
            handleGa4(Ga4Event.ReturnImpression);
            handleGa4(Ga4Event.AvadaImpression);
            handleGa4(Ga4Event.CtaImpression);
          }
        }
      });
    });

    if (recommendAppRef.current) {
      observer.observe(recommendAppRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (recommendAppRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(recommendAppRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour.homePage]);

  return (
    <HomePageStyled>
      <CustomLayout
        layoutProps={{
          title: `Hello ${dataSettings?.settings?.user?.businessName || config.shop.split('.')[0]},`,
        }}
        isVisibleHeader={!dataSettings?.url}
      >
        <Text variant="bodyLg" as="span">
          Welcome to Blockify 🎉
        </Text>
        {checkEmbeddedStatus.data && (
          <Collapsible
            id="banner-check-embedded-app"
            open={bannerNoticeEmbeddedApp && !checkEmbeddedStatus.data?.status}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <div className="mt-16">
              <Banner
                onDismiss={() => {
                  dispatch(bannerSlice.actions.handleBannernoticeEmbeddedApp(false));
                }}
                tone="warning"
                title="Action required: Activate app embed"
              >
                <div className='d-flex flex-column'>
                  <RegularText>
                    Go to <b>Shopify Theme Editor</b> &gt; Turn on <b>Blockify app</b> on the App Embeds section.
                  </RegularText>
                  <div className='mt-8'>
                    <Button target="_blank" url={link.isOpenAppEmbedUrl}>
                      Activate app now
                    </Button>
                  </div>
                </div>
              </Banner>
            </div>
          </Collapsible>
        )}

        <BannerReviewApp />

        <Collapsible
          id="banner-check-free-plan"
          open={userPlanFree && bannerNoticeFreePlan}
          transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
        >
          <div className="mt-16">
            <BannerLimitRules
              isHasLink
              onDismiss={() => {
                dispatch(bannerSlice.actions.handleBannernoticeFreePlan(false));
              }}
              mixpanelName="Home_increase_limit"
            />
          </div>
        </Collapsible>

        <div className="mt-16">
          <div className="space-between">
            <Text as="span" variant="headingLg">
              Overview
            </Text>
            <RegularText>Last 30 days</RegularText>
          </div>
          <div className="mt-8">
            <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 3, xs: 1 }} gap={'500'}>
              <Card>
                <BoldText>Total active rules</BoldText>
                <div className="mt-8">
                  <Text as="span" variant="headingLg">
                    {overview?.totalActiveRules}
                  </Text>
                </div>
              </Card>
              <Card>
                <BoldText>Total visitors</BoldText>
                <div className="mt-8">
                  <Text as="span" variant="headingLg">
                    {overview?.visitors.total}
                  </Text>
                </div>
                <div className="mt-8">
                  <Text as="span" tone={overview?.visitors.compare === 'increase' ? 'success' : 'critical'}>
                    {overview?.visitors.compare === 'increase' ? '+' : '-'}
                    {overview?.visitors.percent || 0}%{' '}
                  </Text>
                  month over month
                </div>
              </Card>
              <Card>
                <BoldText>Total IPs blocked</BoldText>
                <div className="mt-8">
                  <Text as="span" variant="headingLg">
                    {overview?.blocked.total}
                  </Text>
                </div>
                <div className="mt-8">
                  <Text as="span" tone={overview?.blocked.compare === 'increase' ? 'success' : 'critical'}>
                    {overview?.blocked.compare === 'increase' ? '+' : '-'}
                    {overview?.blocked.percent || 0}%{' '}
                  </Text>
                  month over month
                </div>
              </Card>
            </InlineGrid>
          </div>
        </div>

        <div className="mt-16">
          <Collapsible
            id="gift_box"
            open={isShowBannerCampaign}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <FlashSaleCard />
          </Collapsible>
        </div>

        <div className="mt-16">
          <InlineGrid columns={isTablet ? 1 : 2} gap={'400'}>
            <Card padding={'600'}>
              <BoldText>Most popular rules</BoldText>
              <div className="mt-16 most-popular-rules-container">
                {configMostPopularRules.map((item, index) => {
                  return (
                    <div key={index} className="d-flex most-popular-item mt-8">
                      <div>
                        <BoldText>{item.title}</BoldText>
                        <RegularText>{item.description}</RegularText>
                      </div>
                      <Button onClick={item.onAction} variant="primary">
                        Add
                      </Button>
                    </div>
                  );
                })}
              </div>
            </Card>
            <div className="blockify-helper-center-container">
              <img src={dashboardImage.logo} alt="dashboard-logo" />
              <div className="mt-8 blockify-helper-center">
                <Card padding={'600'}>
                  <Text as="span" variant="headingLg">
                    Blockify help center
                  </Text>
                  <div className="mt-16 w-100 padding-custom">
                    <Card>
                      <div className="inline-grid-br-12">
                        <InlineGrid columns={isMobile ? 1 : 2} gap={'0'}>
                          <div
                            onClick={sendMessage}
                            className={
                              isMobile ? 'blockify-helper-center-item border-bottom' : 'blockify-helper-center-item border-right'
                            }
                          >
                            <BoldText>Get support from agent (24/7)</BoldText>
                            <img src={dashboardImage.agent} alt="dashboard-agent" />
                          </div>
                          <div onClick={handleLearnMore} className="blockify-helper-center-item ">
                            <BoldText>Read helpdesk articles</BoldText>
                            <img src={dashboardImage.book} alt="dashboard-book" />
                          </div>
                        </InlineGrid>
                      </div>
                    </Card>
                  </div>
                </Card>
              </div>
            </div>
          </InlineGrid>
        </div>

        <CheckoutRulesBanner />

        <div className="mt-16" id="banner-1">
          <BannerTemplate
            src={promote}
            onClick={() => {
              if (!gaFour.synctrack) {
                handleGa4(Ga4Event.SynctrackClick);
              }

              window.open(
                'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
                '_blank',
                'noopener,noreferrer',
              );
            }}
            isSynctrackBanner
          >
            <BoldText>Auto-sync PayPal tracking info & Stripe. Faster PayPal funds release and build trust with PayPal</BoldText>
            <div className="mt-8">
              <RegularText>
                Synctrack adds tracking info to PayPal & Stripe automatically and instantly on autopilot. That helps you reduce
                Stripe and PayPal disputes, money on hold, PayPal limits, and reserves. Synctrack keeps your business transparent,
                saves time, and avoids PayPal disputes!
              </RegularText>
            </div>
            <div className="mt-16">
              <Button onClick={handleLearnMoreSynctrack}>Learn more</Button>
            </div>
          </BannerTemplate>
        </div>

        <div className="app-recommended" ref={recommendAppRef}>
          <RecommendedApps />
        </div>
      </CustomLayout>
    </HomePageStyled>
  );
}

export default memo(HomePage);
