import useToggle from "@/hooks/useToggle";
import { ICustomTooltip } from "@/types/components";
import { Popover } from "@shopify/polaris";

export const CustomTooltip = ({
  activator,
  children,
  maxWidth = '10rem'
}: ICustomTooltip) => {
  const [isPopoverActive, togglePopover] = useToggle<boolean>(false);

  return (
    <div onMouseLeave={togglePopover} onMouseEnter={togglePopover}>
      <Popover
        active={isPopoverActive}
        activator={activator}
        onClose={togglePopover}
        ariaHaspopup={false}
        sectioned
      >
        <div style={{ maxWidth }}>{children}</div>
      </Popover>
    </div>
  );
};
