import { Ga4Event, Subscription, UserPlan } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import sessionSlice, { gaFourSelector } from '@/redux/slice/session.slice';
import { useDispatch, useSelector } from 'react-redux';

const configSession = [
  {
    value: Ga4Event.SynctrackClick,
    key: 'synctrack',
  },
  {
    value: Ga4Event.ReturnClick,
    key: 'return',
  },
  {
    value: Ga4Event.AvadaClick,
    key: 'avada',
  },
  {
    value: Ga4Event.CtaClick,
    key: 'cta',
  },
  {
    value: Ga4Event.PricingPlan,
    key: 'pricingPlan',
  },
  {
    value: Ga4Event.GeneralSetting,
    key: 'generalSetting',
  },
  {
    value: Ga4Event.CheckoutRulesClick,
    key: 'checkoutRules',
  },
];

export const useGa4 = () => {
  const dispatch = useDispatch();
  const gaFour = useSelector(gaFourSelector);

  const [fetchGA] = apiCaller.useFetchGAMutation();

  const handleGa4 = (
    event: Ga4Event,
    params?: {
      plan: UserPlan;
      type: Subscription;
    },
  ) => {
    fetchGA({
      eventName: event,
      params,
    });
    const gaFourSelected = configSession.find((item) => item.value === event);
    if (gaFourSelected) {
      dispatch(
        sessionSlice.actions.handleGaFour({
          key: gaFourSelected.key as keyof typeof gaFour,
        }),
      );
    }
  };
  return {
    handleGa4,
  };
};
