export const colors = {
  primary: 'rgba(48, 48, 48, 1)',
  gray_background: '#F0F0F0',
  warning: 'rgba(248, 217, 144, 1)',
  critical: 'rgba(245, 107, 82, 1)',
  danger: 'rgba(197, 40, 12, 1)',
  bgHover: '#f1f1f180',
  bgHoverTitle: '#198754',
  border_card: '#d4d4d4',

  visitorOnline: '#3C5370',
  newVisitor: '#53B1C8',
  returning: '#EDB44D',
};
