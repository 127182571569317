import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { useGa4 } from '@/hooks/useGa4';
import { gaFourSelector } from '@/redux/slice/session.slice';
import { IGA4 } from '@/types/components';
import { Button, Card } from '@shopify/polaris';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { rightRecommendApp } from './configRecommend';

const RightRecommendApps = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  const handleClick = useCallback(
    (item: IGA4) => () => {
      window.open(item.href, '_blank', 'noopener,noreferrer');
      if (!gaFour[item.key as keyof typeof gaFour]) {
        handleGa4(item.clickGA);
      }
    },
    [gaFour, handleGa4],
  );

  return (
    <div id="banner-3">
      <Slider {...settings}>
        {rightRecommendApp.map((item) => {
          return (
            <Card padding={'400'} key={item.title}>
              <div className="recommend-app-container">
                <div className="recommend-app-content">
                  <img src={item.logo} alt={item.title} style={{ maxHeight: '59px' }} />
                  <div>
                    <BoldText>{item.title}</BoldText>
                  </div>
                  <RegularText>{item.description}</RegularText>
                </div>
                <Button onClick={handleClick(item)}>{item.label}</Button>
              </div>
            </Card>
          );
        })}
      </Slider>
    </div>
  );
};

export default RightRecommendApps;
