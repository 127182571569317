import { colors } from '@/constants';
import styled from 'styled-components';

export const HeatMapStyled = styled.div`
  .heat-map-container {
    display: flex;
  }

  .list-countries {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .country-item {
      padding: 4px 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 0.25s all linear;
      cursor: pointer;

      &:hover {
        background-color: ${colors.gray_background};
      }

      .name-country {
        width: 100px;
      }

      .progress-country {
        flex: 1;
      }

      .text-percent {
        width: 40px;
      }
    }
  }
`;
