import styled from 'styled-components';
import { colors } from '@/constants/colors';

export const AnalyticsOverviewStyled = styled.div`
  .analytics-container {
    padding: 1rem;
    background-color: ${colors.gray_background};
    display: flex;
    flex-direction: column;
    gap: 18px;
    .daily-chart-container {
      width: 100%;
      display: flex;
      .daily-chart {
        width: 90%;
      }
      .daily-chart-content {
        width: 10%;
        .daily-chart-text {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .daily-chart-container {
        display: flex;
        flex-wrap: wrap;
        .daily-chart {
          width: 100%;
          margin: 0rem;
          overflow: auto;
        }
        .daily-chart-content {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          .daily-chart-text {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
    .chart_container {
      display: flex;
      align-items: center;
      gap: 50px;

      .visitor_online {
        min-width: 140px;
        min-height: 140px;
        border-radius: 100%;
        padding: 10px;
        border: 5px solid ${colors.visitorOnline};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 29px;
      }

      .new_and_returning {
        display: flex;
        flex-direction: column;
        gap: 30px;
        min-width: 80px;

        .new_visitor_bar {
          width: 50px;
          height: 8px;
          border-radius: 4px;
          background-color: ${colors.newVisitor};
        }

        .returning_bar {
          width: 50px;
          height: 8px;
          border-radius: 4px;
          background-color: ${colors.returning};
        }
      }
    }
    .hover-card {
      position: relative;
      overflow: hidden;
      .Polaris-Box {
        min-height: 120px;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      border-radius: 11px;
    }

    .hover-card:hover .overlay {
      opacity: 1;
    }
    .daily-trend-value-hover {
      background-color: transparent;
      color: transparent;
      &:hover {
        background: rgba(255, 100, 0, 1)
      }
    }
  }
`;
