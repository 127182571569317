import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Ga4Event, Subscription, UserPlan } from '@/constants/enum';
import { capitalizeFirstLetter, downgradePlan } from '@/helpers';
import { useGa4 } from '@/hooks/useGa4';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import { IResponseApi } from '@/types/api/response.api';
import {
  Badge,
  BlockStack,
  Button,
  Divider,
  ExceptionList,
  Icon,
  InlineGrid,
  InlineStack,
  List,
  Modal,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris';
import { MagicMajor, TickSmallMinor } from '@shopify/polaris-icons';
import mixpanel from 'mixpanel-browser';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccountPlanStyled } from '../styled';
import ComparePlan from './ComparePlan';
import { DiscountCode } from './DiscountCode';
import PricingFAQs from './PricingFAQs';

interface PlanFeatures {
  title: string | null;
  subTitle: string;
  featureList: string[];
  subTitleService: string;
  serviceDescription: string;
}

interface Plans {
  free: PlanFeatures;
  premium: PlanFeatures;
  enterprise: PlanFeatures;
  'shopify plus'?: PlanFeatures;
}

const premiumContent: PlanFeatures = {
  title: 'All includes in Free',
  subTitle: 'Standout features',
  featureList: [
    'Unlimited Block rules',
    'Unlimited geolocation',
    'Unlimited visitor analytics quota',
    'Auto-block high risk visitors',
    'Auto analyze fraud order',
  ],
  subTitleService: 'Service',
  serviceDescription: 'Live chat support 24/7 (VIP priority)',
};

const enterpriseContent: PlanFeatures = {
  title: 'All includes in Premium',
  subTitle: 'Standout features',
  featureList: [
    'Block TOR connection',
    'Unlimited Block ISP, Referer URL',
    'Auto-block fraud orders',
    'Auto-cancel high risk order',
    'Prevent right click, keyboard shortcuts',
    'Prevent inspect',
  ],
  subTitleService: 'Service',
  serviceDescription: 'Live chat support 24/7 (VIP priority)',
};

const shopifyPlusContent: PlanFeatures = {
  title: 'All includes in Premium, Enterprise',
  subTitle: 'Standout features',
  featureList: [
    'Unlimited rules',
    'Block VPN/Proxy',
    'Block TOR connection',
    'Fraud order analytics',
    'Unlimited visitor analytics',
    'Unlimited block ISP, referrer URL',
    'Auto-block visitors placing fraud orders',
    'Auto-cancel high risk order',
    'Block checkout',
  ],
  subTitleService: 'Service',
  serviceDescription: 'Live chat support 24/7 (VIP priority)',
};

function TablePricingPlan() {
  const { currentPlan, userPlanFree, userPlanPremium, userPlanEnterprise, userPlanShopifyPlus, shopifyPlanPlus, newUser } =
    userPlans();
  const { handleGa4 } = useGa4();
  const isOldUserAndShopifyPlus = shopifyPlanPlus && !newUser;
  const isNewUserAndShopifyPlus = shopifyPlanPlus && newUser;

  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;
  const freeContent: PlanFeatures = {
    title: null,
    subTitle: 'Standout features',
    featureList: [
      `Block IP/ Country: ${maxLimitRules} rules`,
      `Redirect IP/Country: ${maxLimitRules} rules`,
      'Standard visitor analytics quota: First 200 visitors',
      'Standard analyze fraud order',
    ],
    subTitleService: 'Service',
    serviceDescription: 'Live chat support 24/7',
  };
  const trialDaysRemaining = dataSettings?.settings.trialDaysRemaining;

  const listPricing = apiCaller.useListPricingQuery();
  const [activePlanMutation, activePlanMutationStatus] = apiCaller.useGetLinkAccountPlanMutation();
  const [trigger] = apiCaller.useLazyGetGeneralDetailQuery();

  const [expanded, setExpanded] = useState(false);
  const [isOpenModalDiscount, setIsOpenModalDiscount] = useState(false);
  const [isOpenModalDowngradePlan, setIsOpenModalDowngradePlan] = useState(false);
  const [state, setState] = useState({
    plan: UserPlan.FREE,
    subscription: Subscription.Yearly,
    discount: 0,
  });

  useEffect(() => {
    const getData = async () => {
      trigger(config.shop).then((res: any) => {
        setState({
          plan: res.data?.settings.user.plan || UserPlan.FREE,
          subscription: res.data?.settings.user.subscription || Subscription.Yearly,
          discount: res.data?.settings.discount || 0,
        });
      });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLayoutGrid = useMemo(() => {
    let columns;
    if (!shopifyPlanPlus) {
      columns = { xl: 3, lg: 3, md: 1, sm: 1, xs: 1 };
    }
    if ((newUser && shopifyPlanPlus) || (shopifyPlanPlus && userPlanFree)) {
      columns = { xl: 2, lg: 2, md: 1, sm: 1, xs: 1 };
    }
    if (shopifyPlanPlus && !newUser) {
      columns = { xl: 4, lg: 4, md: 2, sm: 2, xs: 2 };
    }

    return columns;
  }, [newUser, shopifyPlanPlus, userPlanFree]);

  const pricingTableData = useMemo(() => {
    let general: Array<IResponseApi.Pricing> = [];
    let free: IResponseApi.Pricing;
    let premium: IResponseApi.Pricing;
    let enterprise: IResponseApi.Pricing;
    let shopifyPlus: IResponseApi.Pricing;

    if (listPricing.data) {
      free = {
        id: 0,
        plan: UserPlan.FREE,
        price: 0,
        annuallyDiscount: 0,
        annuallyDiscountedPrice: 0,
        tag: null,
        campaignPrice: 0,
      };
      premium = listPricing.data.data.find((item) => item.plan === UserPlan.PREMIUM) || free;
      enterprise = listPricing.data.data.find((item) => item.plan === UserPlan.ENTERPRISE) || free;
      shopifyPlus = listPricing.data.data.find((item) => item.plan === UserPlan.SHOPIFYPLUS) || free;

      if (!shopifyPlanPlus) general = [free, premium, enterprise];
      if ((shopifyPlanPlus && newUser) || (shopifyPlanPlus && userPlanFree)) general = [free, shopifyPlus];
      if (shopifyPlanPlus && !newUser) general = [free, premium, enterprise, shopifyPlus];
    }

    let content: Partial<Plans> = {};
    if (!shopifyPlanPlus) {
      content = {
        free: freeContent,
        premium: premiumContent,
        enterprise: enterpriseContent,
      };
    }

    if ((shopifyPlanPlus && newUser) || (shopifyPlanPlus && userPlanFree)) {
      content = {
        free: freeContent,
        // eslint-disable-next-line no-useless-computed-key
        ['shopify plus']: shopifyPlusContent,
      };
    }

    if (shopifyPlanPlus && !newUser) {
      content = {
        free: freeContent,
        premium: premiumContent,
        enterprise: enterpriseContent,
        // eslint-disable-next-line no-useless-computed-key
        ['shopify plus']: shopifyPlusContent,
      };
    }

    return {
      general,
      content,
    };
    // eslint-disable-next-line
  }, [listPricing.data, newUser, shopifyPlanPlus, userPlanFree]);

  const maxDiscount = useMemo(() => {
    if (listPricing.data) {
      const discountArray = listPricing.data?.data.map((item) => item.annuallyDiscount);
      return Math.max(...discountArray);
    }
    return 0;
  }, [listPricing.data]);

  const downgradePlanShopifyPlus = useMemo(() => {
    return {
      [UserPlan.ENTERPRISE]: enterpriseContent.featureList,
      [UserPlan.PREMIUM]: premiumContent.featureList,
      [UserPlan.FREE]: freeContent.featureList,
    };
  }, [freeContent.featureList]);

  const selectPlanHandle = useCallback(
    (plan: UserPlan) => {
      activePlanMutation({
        subscription: state.subscription,
        plan,
      })
        .then((res) => {
          if ('data' in res && res.data.confirmationUrl) {
            window.open(res.data.confirmationUrl, '_top');
          }
        })
        .catch((error) => console.log(error));
    },
    [activePlanMutation, state.subscription],
  );

  const handleChangePlan = useCallback(() => {
    activePlanMutation({ plan: state.plan, subscription: state.subscription }).then((res) => {
      if ('data' in res && res.data.confirmationUrl) {
        window.open(res.data.confirmationUrl, '_top');
      }
      setIsOpenModalDowngradePlan(false);
    });
  }, [activePlanMutation, state.plan, state.subscription]);

  const handleClickSelect = (plan: IResponseApi.Pricing) => {
    handleGa4(Ga4Event.SelectPlan, {
      plan: plan.plan,
      type: state.subscription,
    });

    setState({ ...state, plan: plan.plan });
    if (dataSettings && downgradePlan(currentPlan || UserPlan.FREE, plan.plan)) {
      setIsOpenModalDowngradePlan(true);
    } else {
      selectPlanHandle(plan.plan);
    }
    mixpanel?.track('Select_plan');
  };

  return (
    <AccountPlanStyled isNewUserAndShopifyPlus={isNewUserAndShopifyPlus} isOldUserAndShopifyPlus={isOldUserAndShopifyPlus}>
      <div className="pricing-button-group">
        <Button
          onClick={() => {
            setState({ ...state, subscription: Subscription.Monthly });
          }}
          variant={state.subscription === Subscription.Monthly ? 'secondary' : 'monochromePlain'}
        >
          Monthly plans
        </Button>
        <span className="ml-8">
          <Button
            onClick={() => {
              setState({ ...state, subscription: Subscription.Yearly });
            }}
            variant={state.subscription === Subscription.Yearly ? 'primary' : 'monochromePlain'}
          >
            Annual plans ( up to {String(maxDiscount)}%)
          </Button>
        </span>
      </div>

      <div className="mt-16 discount-btn">
        {state.discount ? (
          <div className="d-flex" style={{ flexDirection: 'column' }}>
            <RegularText>Enjoy your {state.discount}% discount for all monthly plans when upgrading.</RegularText>
            <CustomButton
              onClick={() => {
                setIsOpenModalDiscount(true);
              }}
              variant="plain"
            >
              Replace your discount code
            </CustomButton>
          </div>
        ) : (
          <CustomButton
            onClick={() => {
              setIsOpenModalDiscount(true);
            }}
            variant="plain"
          >
            Apply your discount code
          </CustomButton>
        )}
      </div>

      <div className="plan-table">
        <InlineGrid columns={renderLayoutGrid} gap="400">
          {pricingTableData.general.map((plan, idx) => {
            const planItem = pricingTableData.content[plan.plan]!;

            return (
              <div key={plan.id} className="plan-table-item">
                {plan.tag && idx !== 3 ? (
                  <div className="pricing-tag">
                    <Badge tone="info">{plan.tag}</Badge>
                  </div>
                ) : null}

                {plan.plan === UserPlan.FREE ? (
                  <div className="free_plan_price">
                    <Text as="h4" variant="heading2xl">
                      Free
                    </Text>
                  </div>
                ) : (
                  <div className="remaining_plan_price">
                    <p className="pricing-title">{plan.plan.toUpperCase()}</p>
                    <InlineStack gap="200" blockAlign="start">
                      <p className="h6 pricing-plan">
                        {state.subscription === Subscription.Monthly
                          ? `$${plan.campaignPrice ?? plan.price}`
                          : `$${plan.annuallyDiscountedPrice}`}
                      </p>

                      <BlockStack gap="050">
                        <Text as="span" variant="bodyMd">
                          USD
                        </Text>

                        {plan.campaignPrice && state.subscription === Subscription.Monthly ? (
                          <>
                            <Text as="span" variant="headingMd">
                              first month then{' '}
                              <Text as="span" variant="headingMd" tone="subdued">
                                {state.subscription === Subscription.Monthly
                                  ? `$${plan.price}`
                                  : `$${plan.annuallyDiscountedPrice}`}
                              </Text>
                            </Text>
                            <Text as="span" variant="bodyMd">
                              (valid until Sep 20)
                            </Text>
                          </>
                        ) : (
                          <Text as="span" variant="headingMd">
                            /month
                          </Text>
                        )}
                      </BlockStack>

                      {state.subscription === Subscription.Yearly ? (
                        <p>
                          ({plan.annuallyDiscount}% OFF <span className="pricing-plan-strike">${plan.price}</span>)
                        </p>
                      ) : null}
                    </InlineStack>
                  </div>
                )}

                <Divider />

                <div className="content-plan">
                  <BlockStack gap="050" inlineAlign="start">
                    {planItem?.title ? (
                      <InlineStack gap="050" blockAlign="center" wrap={false}>
                        <div>
                          <Icon source={MagicMajor} tone="base" />
                        </div>
                        <Text as="span" variant="headingMd">
                          {planItem.title}
                        </Text>
                      </InlineStack>
                    ) : (
                      <div className="mt-16"></div>
                    )}

                    <BlockStack gap="150">
                      <div className="mb-8">
                        <Text as="p" variant="headingSm">
                          {planItem?.subTitle}
                        </Text>
                      </div>

                      <ExceptionList
                        items={planItem.featureList.map((item) => ({
                          icon: TickSmallMinor,
                          description: item,
                        }))}
                      />
                    </BlockStack>

                    <BlockStack gap="150">
                      <Text as="span" variant="headingMd">
                        {planItem.subTitleService}
                      </Text>
                      <ExceptionList items={[{ icon: TickSmallMinor, description: planItem.serviceDescription }]} />
                    </BlockStack>
                  </BlockStack>
                </div>

                {isLoading ? (
                  <SkeletonDisplayText maxWidth="100%" size="extraLarge" />
                ) : (
                  <div className="plan-table-layout-content-btn">
                    {(state.subscription === dataSettings?.settings.user.subscription && currentPlan === plan.plan) ||
                    (currentPlan === plan.plan && userPlanFree) ? (
                      <Button fullWidth disabled>
                        Your current plan
                      </Button>
                    ) : (
                      <CustomButton
                        fullWidth
                        loading={activePlanMutationStatus.isLoading && state.plan === plan.plan && !isOpenModalDowngradePlan}
                        variant="primary"
                        onClick={() => handleClickSelect(plan)}
                      >
                        {plan.campaignPrice && state.subscription === Subscription.Monthly
                          ? 'Get the deal'
                          : trialDaysRemaining && plan.plan !== UserPlan.FREE
                          ? `Start ${trialDaysRemaining}-day Free trial`
                          : 'Select'}
                      </CustomButton>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </InlineGrid>
      </div>

      {!newUser && shopifyPlanPlus ? null : (
        <>
          <BlockStack gap="200" inlineAlign="center">
            <Text variant="headingMd" as="h3">
              Compare plan features
            </Text>
            <Text variant="bodyLg" as="h4" tone="disabled">
              All details of the Blockify’s plans
            </Text>
            <Button
              variant="primary"
              size="large"
              textAlign="left"
              disclosure={expanded ? 'up' : 'down'}
              onClick={() => {
                setExpanded(!expanded);
                mixpanel?.track('Show_detailed_plans');
              }}
            >
              {expanded ? 'Hide detailed plans' : 'Show detailed plans'}
            </Button>
          </BlockStack>

          {expanded && <ComparePlan />}
        </>
      )}

      <PricingFAQs />

      <Modal
        open={isOpenModalDowngradePlan}
        onClose={() => setIsOpenModalDowngradePlan(false)}
        title="Downgrade plan"
        primaryAction={{
          content: 'Stay on current plan',
          onAction: () => setIsOpenModalDowngradePlan(false),
        }}
        secondaryActions={[
          {
            loading: activePlanMutationStatus.isLoading,
            content: 'Downgrade',
            onAction: handleChangePlan,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>
            Downgrading means you won't have access to the features provided in your{' '}
            <b>{capitalizeFirstLetter(currentPlan || '')}</b> plan
          </RegularText>

          <List type="bullet">
            {userPlanFree ? freeContent.featureList.map((item) => <List.Item key={item}>{item}</List.Item>) : null}

            {userPlanPremium ? premiumContent.featureList.map((item) => <List.Item key={item}>{item}</List.Item>) : null}
            {userPlanEnterprise ? enterpriseContent.featureList.map((item) => <List.Item key={item}>{item}</List.Item>) : null}
            {userPlanShopifyPlus
              ? downgradePlanShopifyPlus[state.plan as keyof typeof downgradePlanShopifyPlus]?.map((str, index) => {
                  return <List.Item key={index}>{str}</List.Item>;
                })
              : null}
            <List.Item>Live chat support (24/7) {!userPlanFree ? '(VIP priority)' : ''} </List.Item>
          </List>

          <RegularText>
            Please be aware that your store may become vulnerable to potential threats from malicious actors.
          </RegularText>
        </Modal.Section>
      </Modal>
      <DiscountCode isOpen={isOpenModalDiscount} handleClose={() => setIsOpenModalDiscount(false)} />
    </AccountPlanStyled>
  );
}

export default memo(TablePricingPlan);
