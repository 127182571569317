import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Subscription, UserPlan } from '@/constants/enum';
import { IResponseApi } from '@/types/api/response.api';

interface IState {
  url: string;
  quoteAppInstalled: boolean;
  settings: {
    id: number;
    autoBlockFraudOrders: boolean;
    activeVpnProxy: boolean;
    allowVpnFromPrivateRelay: boolean;
    blockingMode: number;
    tempLogoImage: string;
    tempTitle: string;
    tempDescription: string;
    protectContents: boolean;
    disableRightClick: boolean;
    disableKeyboardShortcut: boolean;
    disableInspect: boolean;
    disablePrint: boolean;
    disableScreenCapture: boolean;
    disableSpyExtensions: boolean;
    autoBlock: boolean;
    torBlock: boolean;
    ip: string;
    createdAt: Date;
    instanceId: string;
    welcomeStep: number;
    identifierId: string;
    hasReview?: boolean;
    timezone?: string;
    linkRedirectVpnProxy?: string;
    typeRestrictionVpnProxy?: string;
    discount: number;
    displayWelcome?: boolean;
    vpnRiskCore?: number;
    showWatermark: boolean;
    testBlockUrl?: string;
    blockingTemplateId?: number;
    campaigns: IResponseApi.PlanHistory[];
    shopifyPlan: string;
    shopTimezone: string;
    status?: string;
    trialDaysRemaining: number;
    orderRiskScore?: string;
    user: {
      id: number;
      orderScope: boolean;
      paymentCustomizationScope: boolean;
      instanceId: string;
      refreshToken: string;
      siteId: string;
      siteName: string;
      businessName: string;
      lastInstalledDate?: string;
      installedDate: string;
      notify: boolean;
      isActive: boolean;
      shopInfo: string;
      confirmationUrl: string;
      plan: UserPlan;
      subscription: Subscription;
      enableApp?: boolean;
      hasScript?: boolean;
      validationScope?: boolean;
      readContentScope?: boolean;
      numberRuleLimit?: number;
      userScopes?: string;
    };
    blockTemplate: {
      logoImage: string;
      title: string;
      titleColor: string;
      description: string;
      descriptionColor: string;
      backgroundType: string;
      background: string;
    } | null;
  };
  summaryStats: {
    blockingRule: number;
    redirectingRule: number;
    whitelistRule: number;
  };
}
const initialState: { data: IState | null; loading: boolean } = {
  data: null,
  loading: false,
};
export const dataSettingsSlice = createSlice({
  name: 'dataSettings',
  initialState,
  reducers: {
    handleSettings: (state, action: PayloadAction<IState>) => {
      state.data = action.payload;
    },
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const dataSettingsSelector = createSelector(
  (state: RootState) => state.dataSettings,
  (state) => state.data,
);
export const loadingSelector = createSelector(
  (state: RootState) => state.dataSettings,
  (state) => state.loading,
);

export default dataSettingsSlice;
