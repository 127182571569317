import { BREAKPOINT, UserPlan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import useScope from '@/hooks/Scope';
import userPlans from '@/hooks/userPlans';
import { loadingSelector } from '@/redux/slice/dataSettings.slice';
import { ISettingToggle } from '@/types/components';
import { Badge, Button, Card, InlineGrid, Link, SkeletonDisplayText, Text, Tooltip } from '@shopify/polaris';
import mixpanel from 'mixpanel-browser';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { CustomTooltip } from '../CustomTooltip';
import RegularText from '../RegularText';
import { CustomSettingToggleStyled } from './styled';

const CustomSettingToggle = ({ ...props }: ISettingToggle.IProps) => {
  const navigate = useNavigate();
  const scope = useScope();
  const isLoading = useSelector(loadingSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { userPlanFree, userPlanPremium, shopifyPlanPlus } = userPlans();
  const isUserPlanFree = () => props.minActivePlan === UserPlan.PREMIUM && userPlanFree;
  const isUserPlanEnterprise = () => props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium);
  const isUserPlanEnterpriseWithoutShopifyPlus = () =>
    props.minActivePlan && props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium) && !shopifyPlanPlus;
  const isUserPlanEnterpriseWithShopifyPlus = () =>
    props.minActivePlan && props.minActivePlan === UserPlan.ENTERPRISE && (userPlanFree || userPlanPremium) && shopifyPlanPlus;
  const isUserPlanShopifyPlus = () => props.minActivePlan && props.minActivePlan === UserPlan.SHOPIFYPLUS && userPlanFree;
  const condition =
    isUserPlanFree() ||
    isUserPlanEnterprise() ||
    isUserPlanEnterpriseWithoutShopifyPlus() ||
    isUserPlanEnterpriseWithShopifyPlus() ||
    isUserPlanShopifyPlus();
  const renderBadge = () => {
    if (isUserPlanFree()) {
      return <div className='tone-yellow'><Badge>Premium plan or higher</Badge></div>
    }
    if (isUserPlanEnterpriseWithoutShopifyPlus()) {
      return <div className='tone-orange'><Badge>Enterprise plan</Badge>
      </div>
    }
    if (isUserPlanEnterpriseWithShopifyPlus()) {
      return <div className='tone-orange'><Badge>Enterprise plan or higher</Badge></div>
    }
    if (isUserPlanShopifyPlus()) {
      return <div className='tone-orange'><Badge tone="critical">Shopify plus plan</Badge></div>
    }
    return null;
  };
  return (
    <CustomSettingToggleStyled>
      <Card>
        <InlineGrid columns={isMobile || props.oneColumn ? 1 : ['twoThirds', 'oneThird']}>
          <div>
            <div className="toggle-settings-title">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Text as="h5" variant="headingMd">
                  {props.title}
                  <div className="ml-4">
                    <div className='d-flex'>
                      <Badge tone={props.settingToggleProps.enabled ? 'success' : 'critical'}>
                        {props.settingToggleProps.enabled ? 'On' : 'Off'}
                      </Badge>
                      {renderBadge()}
                    </div>
                  </div>
                </Text>
                {props.oneColumn ? (
                  <div className="toggle-btn">
                    {isLoading && props.settingToggleProps.action?.loading ? (
                      <SkeletonDisplayText size="small" />
                    ) : condition ? (
                      <Button
                        onClick={() => {
                          navigate(PATH.PRICING_PLAN);
                          props?.mixpanelKey && mixpanel?.track(props?.mixpanelKey);
                        }}
                        loading={props.settingToggleProps.action?.loading}
                        variant="primary"
                      >
                        Turn on by upgrade
                      </Button>
                    ) : (
                      <Button
                        disabled={scope.isViewOnly}
                        onClick={props.settingToggleProps.action?.onAction}
                        loading={props.settingToggleProps.action?.loading}
                        variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                      >
                        {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
                      </Button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            {props.children ? (
              <div className="mt-8">
                {typeof props.children === 'string' ? <RegularText>{props.children}</RegularText> : props.children}
              </div>
            ) : null}
          </div>
          {!props.oneColumn ? (
            <div className="toggle-btn">
              {isLoading && props.settingToggleProps.action?.loading ? (
                <SkeletonDisplayText size="small" />
              ) : condition ? (
                <Button
                  onClick={() => {
                    navigate(PATH.PRICING_PLAN);
                    props?.mixpanelKey && mixpanel?.track(props?.mixpanelKey);
                  }}
                  loading={props.settingToggleProps.action?.loading}
                  variant="primary"
                >
                  Turn on by upgrade
                </Button>
              ) : (
                <>
                  {!!props.contentTooltip ?
                    <Tooltip content={props.contentTooltip}>
                      <Button
                        disabled
                        onClick={props.settingToggleProps.action?.onAction}
                        loading={props.settingToggleProps.action?.loading}
                        variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                      >
                        {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
                      </Button>
                    </Tooltip> :
                    props.disabled ? <Button
                      disabled={scope.isViewOnly || props.disabled}
                      onClick={props.settingToggleProps.action?.onAction}
                      loading={props.settingToggleProps.action?.loading}
                      variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                    >
                      {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
                    </Button> :
                      props.contentTooltipWithLink ?
                        <CustomTooltip
                          activator={
                            <div className="btn-toggle">
                              <RegularText>
                                {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
                              </RegularText>
                            </div>
                          }
                        >
                          <Link onClick={props.onClick}>{props.contentTooltipWithLink}</Link>
                        </CustomTooltip>
                        :
                        <Button
                          disabled={scope.isViewOnly || props.disabled}
                          onClick={props.settingToggleProps.action?.onAction}
                          loading={props.settingToggleProps.action?.loading}
                          variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                        >
                          {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
                        </Button>
                  }
                </>
              )}
            </div>
          ) : null}
        </InlineGrid>
      </Card>
    </CustomSettingToggleStyled>
  );
};

export default memo(CustomSettingToggle);
