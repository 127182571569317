import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import bannerSlice, { bannerReviewSetUpAppSelector } from '@/redux/slice/banner.slice';
import { dataTableSelector } from '@/redux/slice/blockCheckout.slice';
import { blackListTableSelector } from '@/redux/slice/blockCheckoutAccess';
import { whiteListTableSelector } from '@/redux/slice/blockList.slice';
import { Banner, Button, Collapsible } from '@shopify/polaris';
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import RegularText from '../RegularText';

function BannerReviewApp() {
  const dispatch = useDispatch();
  const bannerReviewSetUpApp = useSelector(bannerReviewSetUpAppSelector);
  const blackListTable = useSelector(blackListTableSelector);
  const whiteListTable = useSelector(whiteListTableSelector);
  const whitelist = apiCaller.useFetchSettingListQuery({
    ...whiteListTable,
  });
  const blacklist = apiCaller.useFetchSettingListQuery({
    ...blackListTable,
  });
  const dataTable = useSelector(dataTableSelector);
  const blockCheckout = apiCaller.useGetBlockCheckoutRulesQuery({
    identifierId: config.shop || '',
    criteria: dataTable.criteria,
    page: 1,
    perPage: Number(dataTable.perPage),
    sortDirection: dataTable.sort,
  });
  const sendMessageReviewApp = () => {
    const sendText = 'I would like to request a review of my Blockify setup.';
    try {
      $crisp.push(['set', 'session:event', ['ReviewAppBlockify']]);
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
    mixpanel?.track('Get_support_from_agent');
  };
  return (
    <div className={bannerReviewSetUpApp ? "mb-16" : ''}>
      <Collapsible
        id="banner-check-review-app"
        open={bannerReviewSetUpApp && (whitelist.data?.settingList.length !== 0 || blacklist.data?.settingList.length !== 0 || blockCheckout.data?.rules.length !== 0)}
        transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
      >
        <div className="mt-16">
          <Banner
            onDismiss={() => {
              dispatch(bannerSlice.actions.handleShowBannerReviewSetUpApp(false));
            }}
            tone="info"
            title="Ensure your rules work. Contact a live agent to review your setup."
          >
            <div className='d-flex flex-column'>
              <RegularText>
                Are you facing issues with bots and malicious actors disrupting your store, but unsure how to handle them? Struggling to use the app or find it not performing as expected? Let us review your store setup. Feel free to message us, we're here to assist you.
              </RegularText>
              <div className='mt-8'>
                <Button target="_blank" onClick={sendMessageReviewApp}>
                  Review set up
                </Button>
              </div>
            </div>
          </Banner>
        </div>
      </Collapsible>
    </div>
  )
}

export default BannerReviewApp