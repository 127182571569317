import { recommendAppImage } from '@/asset/images/recommendedApps';
import { Ga4Event } from '@/constants/enum';

export const leftRecommendApp: {
  title: string;
  description: string;
  logo: string;
  href: string;
  label?: string;
  clickGA: Ga4Event;
  key: string;
}[] = [
  {
    title: 'Synctrack PayPal Tracking Sync',
    description: 'Speed up PayPal verification to get funds faster, avoid chargebacks, and account limits.',
    logo: recommendAppImage.synctrack,
    href: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
    clickGA: Ga4Event.SynctrackClick,
    key: 'synctrack',
  },
  {
    title: 'Synctrack ‑ Returns & Exchanges',
    description: 'Streamline returns, exchanges, refunds to delight customers & retain revenue.',
    logo: recommendAppImage.returnDrive,
    href: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp.',
    clickGA: Ga4Event.ReturnClick,
    key: 'return',
  },
];

export const rightRecommendApp = [
  {
    title: 'Avada GDPR Cookie Consent',
    description: `Compliance with privacy laws is a must for global sellers. One solution ensures compliance across all regions - so you don't have to.`,
    logo: recommendAppImage.avada,
    href: 'https://apps.shopify.com/avada-cookie-bar?utm_source=Blockify&utm_medium=inappbanner&utm_campaign=cookieBar_partnership',
    label: 'Get Cookie',
    clickGA: Ga4Event.AvadaClick,
    key: 'avada',
  },
  {
    title: 'Floatify - CTA, Social Buttons',
    description:
      'With simple solution, customers can contact your shop faster with many tools on the same program to increase sales.',
    logo: recommendAppImage.CTA,
    href: 'https://apps.shopify.com/cta-buttons??utm_source=ipblock&utm_medium=inapprcm&utm_campaign=partner',
    label: 'Check now',
    clickGA: Ga4Event.CtaClick,
    key: 'cta',
  },
];
