import BannerGrantPermission from '@/components/BannerGrantPermission';
import BannerLimitRules from '@/components/BannerLimitRules';
import BoldText from '@/components/BoldText';
import ButtonSupport from '@/components/ButtonSupport';
import CustomDatePicker from '@/components/datePicker';
import Layout from '@/components/layout';
import ProcessOldOrders from '@/components/ProcessOldOrders';
import RegularText from '@/components/RegularText';
import SettingToggle from '@/components/settingToggle';
import { config } from '@/config';
import { Enum } from '@/constants';
import { ScopeGroups, UserPlan } from '@/constants/enum';
import { checkShowErrorInline, dateToTimeStamp, disablePlan, handleToastMutation } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { fraudOrderBannerSelector } from '@/redux/slice/banner.slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import fraudOrdersSlice, { fraudOrdersTableSelector, titleBtnDatePickerSelector } from '@/redux/slice/fraudOrders';
import toastSlice from '@/redux/slice/toast.slice';
import { Badge, BlockStack, Button, Card, Checkbox, Icon, Link, RangeSlider } from '@shopify/polaris';
import { OrdersMajor, RefreshMajor } from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from './Components/Analytics';
import Table from './Components/Table';
import { FraudOrdersStyled } from './styled';
import _debounce from 'lodash/debounce';

const PrimaryAction = (): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const dataSettings = useSelector(dataSettingsSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const getListOrder = apiCaller.useGetListOrderQuery({
    endDate: dateToTimeStamp(fraudOrdersTable.endDate),
    startDate: dateToTimeStamp(fraudOrdersTable.startDate),
    ip: fraudOrdersTable.search,
    isVpn: fraudOrdersTable.vpn,
    page: fraudOrdersTable.page,
    perPage: Number(fraudOrdersTable.perPage),
    sortBy: fraudOrdersTable.sortBy,
    sortDirection: fraudOrdersTable.sort.toUpperCase(),
  });
  const getOrderSummary = apiCaller.useFraudOrdersSummaryQuery({
    identifierId: config.shop,
    startDate: dateToTimeStamp(fraudOrdersTable.startDate),
    endDate: dateToTimeStamp(fraudOrdersTable.endDate),
  });
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(
      fraudOrdersSlice.actions.handleFraudOrdersTable({
        ...fraudOrdersTable,
        startDate,
        endDate,
      }),
    );
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(fraudOrdersSlice.actions.handleTitleBtnDatePicker(title));
  };
  const handleRefresh = () => {
    Promise.all([getListOrder.refetch(), getOrderSummary.refetch]);
  };
  return (
    <div className="d-flex mr-8">
      <Button loading={getListOrder.isFetching || getOrderSummary.isLoading} onClick={handleRefresh} icon={RefreshMajor}>
        Refresh
      </Button>
      <div className="ml-8">
        <CustomDatePicker
          titleButton={titleBtn}
          setTitleButton={onSaveTitleBtnDatePicker}
          startDate={fraudOrdersTable.startDate}
          endDate={fraudOrdersTable.endDate}
          onSave={onSaveDatePicker}
          isShowSelectedTime={true}
          conditions={false}
        />
      </div>
      {dataSettings?.settings.user.orderScope ? (
        <div className="ml-8">
          <Button
            onClick={() => {
              setIsOpenModal(true);
            }}
            icon={<Icon source={OrdersMajor} tone="base" />}
          >
            Analyze old orders
          </Button>
          <ProcessOldOrders isOpen={isOpenModal} handleIsOpen={(value) => setIsOpenModal(value)} />
        </div>
      ) : null}
    </div>
  );
};

const FraudOrders = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const fraudOrderBanner = useSelector(fraudOrderBannerSelector);
  const { userPlanFree, userPlanPremium, shopifyPlanPlus, userPlanEnterprise } = userPlans();
  const [activeFraudOrder, activeFraudOrderStatus] = apiCaller.useUpdateAutoCancelHighRiskOrderMutation();
  const [updateScope] = apiCaller.useLazyGetUrlUpdatePaymentScopeQuery();
  const { data } = apiCaller.useGetInfoAutoCancelHighRiskOrderQuery();
  const [buttonClick, setButtonClick] = useState('');
  const [state, setState] = useState({
    autoBlockOrder: false,
    autoCancelOrder: false,
    reStock: true,
    buyer: true,
    merchant: true,
    orderRiskScore: 0
  });
  const handleAutoBlockFraudOrders = useCallback(
    () => {
      const newAutoBlockOrderStatus = !data?.data.enabledAutoBlockFraudOrder;

      setState((prevState) => ({
        ...prevState,
        autoBlockOrder: newAutoBlockOrderStatus,
      }));

      activeFraudOrder({
        enabledAutoBlockFraudOrder: newAutoBlockOrderStatus,
        orderRiskScore: state.orderRiskScore,
        urlParams: config.urlParams
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
      setButtonClick('1')
    }, [activeFraudOrder, dispatch, state.orderRiskScore, data]
  );
  const handleAutoCancelFraudOrders = useCallback(
    () => {
      const newAutoCancelOrderStatus = !data?.data.enabledAutoCancelFraudOrder;
      const newReStockStatus = true;
      const newSendBuyerNoticeStatus = true;
      const newSendMerchantNoticeStatus = true;

      setState((prevState) => ({
        ...prevState,
        autoCancelOrder: newAutoCancelOrderStatus,
        reStock: newReStockStatus,
        buyer: newSendBuyerNoticeStatus,
        merchant: newSendMerchantNoticeStatus,
      }));

      activeFraudOrder({
        enabledAutoCancelFraudOrder: newAutoCancelOrderStatus,
        enabledRestock: newReStockStatus,
        enabledSendBuyerNotice: newSendBuyerNoticeStatus,
        enabledSendMerchantNotice: newSendMerchantNoticeStatus,
        urlParams: config.urlParams
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
      setButtonClick('2')
    }, [activeFraudOrder, dispatch, data]
  );

  const handleChangeAutoCancelOrder = useCallback(
    (field: string) => (newChecked: boolean) => {
      const updatedData = {
        ...state,
        [field]: newChecked
      };

      setState(updatedData);

      activeFraudOrder({
        enabledAutoCancelFraudOrder: updatedData.autoCancelOrder,
        enabledRestock: updatedData.reStock,
        enabledSendBuyerNotice: updatedData.buyer,
        enabledSendMerchantNotice: updatedData.merchant,
        urlParams: config.urlParams
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
      setButtonClick('3')
    },
    [activeFraudOrder, dispatch, state]
  );
  // eslint-disable-next-line
  const debouncedUpdateOrderRiskScore = useCallback(
    _debounce((value) => {
      activeFraudOrder({
        orderRiskScore: value,
        urlParams: config.urlParams
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    }, 500),
    [activeFraudOrder, dispatch]
  );

  const handleRangeSliderChange = useCallback(
    (value: number) => {
      setState((prevState) => ({
        ...prevState,
        orderRiskScore: value,
      }));
      debouncedUpdateOrderRiskScore(value);
    },
    [debouncedUpdateOrderRiskScore],
  );

  // GRAND PERMISSION FOR BOTH READ & WRITE ORDERS
  const handleUpdateScope = () => {
    if (dataSettings) {
      updateScope({
        scopeGroups: ScopeGroups.processOrders
      }).then((res) => {
        if (res.data?.url) {
          window.open(res.data.url, '_blank');
        }
      });
    }
  };

  const handleLearnMore = () => {
    window.open('https://docs.ipblocker.io/getting-started/fraud-orders/auto-cancel-high-risk-orders', '_blank');
  }

  useEffect(() => {
    if (data && data.data) {
      setState({
        autoBlockOrder: data.data.enabledAutoBlockFraudOrder,
        autoCancelOrder: data.data.enabledAutoCancelFraudOrder,
        reStock: data.data.enabledRestock,
        buyer: data.data.enabledSendBuyerNotice,
        merchant: data.data.enabledSendMerchantNotice,
        orderRiskScore: Number(data.data.orderRiskScore) || 0
      });
    }
  }, [data]);

  return (
    <Layout layoutProps={{ title: 'Fraud orders analytics', fullWidth: true, primaryAction: <ButtonSupport /> }}>
      <FraudOrdersStyled>
        <BannerGrantPermission
          title="Important step to use this feature"
          isVisible={!dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) && !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)}
          enum={ScopeGroups.processOrders}
          children="To use this feature, we need permission to view your store orders data."
          variant="primary"
        />
        {fraudOrderBanner && userPlanFree && (
          <div className="mb-16">
            <BannerLimitRules
              onDismiss={() => {
                dispatch(slice.banner.actions.handleFraudOrderBanner(false));
              }}
              mixpanelName='Fraud_order_increase_limit'
            />
          </div>
        )}
        <div className="mb-16">
          <PrimaryAction />
        </div>
        <SettingToggle
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          title="Auto-block visitors placing fraud orders"
          settingToggleProps={{
            enabled: !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.autoBlockOrder,
            action: { onAction: handleAutoBlockFraudOrders, loading: (activeFraudOrderStatus.isLoading || isLoading) && buttonClick === '1' },
          }}
          mixpanelKey="Fraud_order_button"
          contentTooltipWithLink={dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders) ? undefined : 'Please grant permission to turn on this feature'}
          onClick={handleUpdateScope}
          disabled={!dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) && !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)}
        >
          <RegularText>
            Turn on this feature so that when there is an order that Shopify detects as high risk of fraud, we will automatically
            add the IPs that created this high risk order to the blocked list.{' '}
            <Link target="_blank" url="https://docs.ipblocker.io/getting-started/fraud-orders">
              Learn about fraud orders.
            </Link>
            {state.autoBlockOrder && userPlanEnterprise && dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders) ? (
              <div className="mt-16 mb-16 range">
                <RangeSlider
                  output
                  label={<RegularText>Fraud order score starts at</RegularText>}
                  value={state.orderRiskScore}
                  suffix={
                    <p
                      style={{
                        minWidth: '24px',
                        textAlign: 'right',
                      }}
                    >
                      {state.orderRiskScore}
                    </p>
                  }
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={handleRangeSliderChange}
                />
                <div className="risk-score">
                  ({state.orderRiskScore < 0.4 ? 'Low risk' : state.orderRiskScore < 0.7 ? 'Medium risk' : 'High risk'})
                </div>
              </div>
            ) : null}
          </RegularText>
        </SettingToggle>
        <div className='mt-16'>
          <SettingToggle
            minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
            title={<BoldText>Auto-cancel high risk orders <span><Badge tone="info">Beta</Badge></span></BoldText>}
            settingToggleProps={{
              enabled: !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && state.autoCancelOrder,
              action: { onAction: handleAutoCancelFraudOrders, loading: (activeFraudOrderStatus.isLoading || isLoading) && buttonClick === '2' },
            }}
            mixpanelKey="Auto_cancel_high_risk_button"
            contentTooltipWithLink={dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) ? undefined : 'Please grant permission to turn on this feature'}
            onClick={handleUpdateScope}
            disabled={!dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) && !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.readOrders)}
          >
            <>
              <RegularText>Turn on this feature, and the high-risk orders will be automatically canceled based on the condition you set up. <Link onClick={handleLearnMore}>Learn more.</Link></RegularText>
              {userPlanFree || userPlanPremium || !state.autoCancelOrder || !dataSettings?.settings?.user?.userScopes?.split(',')?.includes(ScopeGroups.writeOrders) ? null
                :
                <BlockStack>
                  <Checkbox
                    label="Re-stock inventory after canceling the high-risk orders"
                    checked={state.reStock}
                    onChange={handleChangeAutoCancelOrder('reStock')}
                  />
                  <Checkbox
                    label="Send cancellation confirmation email to customers"
                    checked={state.buyer}
                    onChange={handleChangeAutoCancelOrder('buyer')}
                  />
                  <Checkbox
                    label="Send cancellation notice to store owners"
                    checked={state.merchant}
                    onChange={handleChangeAutoCancelOrder('merchant')}
                  />
                </BlockStack>
              }
            </>
          </SettingToggle>
        </div>

        <div className="mt-16">
          <Card>
            <div className="checkout-rules-banner-heading">
              <BoldText>
                Customize your checkout page with Blockify: Checkout Rules
                <span className="ml-8">
                  <Badge tone="success">Free</Badge>
                </span>
              </BoldText>
              <Button variant="primary" url="https://apps.shopify.com/blockify-checkout-rules" target="_blank">
                Install app
              </Button>
            </div>

            <div className="mt-8">
              <RegularText>
                Validate checkout fields on the checkout page. Also, you can personalize checkout by customizing payment and
                shipping methods.
              </RegularText>
            </div>
          </Card>
        </div>

        <div className="mt-16">
          <div>
            <Analytics />
          </div>
        </div>

        <div className="mt-16">
          <div>
            <Table />
          </div>
        </div>
      </FraudOrdersStyled>
    </Layout >
  );
};

export default memo(FraudOrders);
