import { images } from '@/asset';
import { Enum } from '@/constants';
import { ConditionHidePayment, CriteriaHidePayment, CriteriaType, DayType, IsVpn, NameType, OrderRisk } from './enum';

export const perPageOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
];

const typeList = [
  {
    label: 'Black list',
    value: Enum.ListType.BlackList,
  },
  {
    label: 'White list',
    value: Enum.ListType.WhiteList,
  },
];

export const orderTypeOptions = [
  {
    label: 'Low risk',
    value: OrderRisk.Low,
  },
  {
    label: 'Medium risk',
    value: OrderRisk.Medium,
  },
  {
    label: 'High risk',
    value: OrderRisk.High,
  },
  {
    label: 'All',
    value: '',
  },
];

export const orderVpnOptions = [
  {
    label: IsVpn.Yes,
    value: IsVpn.Yes,
  },
  {
    label: IsVpn.No,
    value: IsVpn.No,
  },
  {
    label: IsVpn.NotDetacted,
    value: IsVpn.NotDetacted,
  },
  {
    label: 'All',
    value: '',
  },
];

const criteriaFilters = [
  {
    label: 'IP address',
    value: Enum.CriteriaType.IpAddress,
  },
  {
    label: 'IP address start with',
    value: Enum.CriteriaType.IpAddressStartWith,
  },
  {
    label: 'Country',
    value: Enum.CriteriaType.Country,
  },
  {
    label: 'State/Province',
    value: Enum.CriteriaType.Province,
  },
  {
    label: 'Internet service provider (ISP)',
    value: Enum.CriteriaType.ISP,
  },
  {
    label: 'Collection',
    value: Enum.CriteriaType.Collection,
    hiddenBlockAccess: true,
  },
  {
    label: 'Products',
    value: Enum.CriteriaType.Product,
    hiddenBlockAccess: true,
  },
  {
    label: 'Specific Page',
    value: Enum.CriteriaType.SpecificPage,
    hiddenBlockAccess: true,
  },
  {
    label: 'Referral URL',
    value: Enum.CriteriaType.ReferralLink,
    hiddenBlockAccess: true,
  },
  {
    label: 'User Agent',
    value: Enum.CriteriaType.UserAgent,
  },
  {
    label: 'IP ranges',
    value: Enum.CriteriaType.IpRanges,
  },
];
const criteriaFiltersBlockCheckout = [
  {
    label: 'IP address',
    value: Enum.CriteriaType.IpAddress,
  },
  {
    label: 'IP address start with',
    value: Enum.CriteriaType.IpAddressStartWith,
  },
  {
    label: 'Country',
    value: Enum.CriteriaType.Country,
  },
  {
    label: 'State/Province',
    value: Enum.CriteriaType.Province,
  },
  {
    label: 'Internet service provider (ISP)',
    value: Enum.CriteriaType.ISP,
  },
  {
    label: 'User Agent',
    value: Enum.CriteriaType.UserAgent,
  },
  {
    label: 'IP ranges',
    value: Enum.CriteriaType.IpRanges,
  },
];

const blockTypeOptions = [
  {
    label: 'Block',
    value: Enum.ActionType.Block,
  },
  {
    label: 'Redirect',
    value: Enum.ActionType.Redirect,
  },
];

const listTypeOptions = [
  {
    content: 'Block',
    id: Enum.ActionType.Block,
  },
  {
    content: 'Redirect',
    id: Enum.ActionType.Redirect,
  },
  {
    content: 'Whitelist',
    id: Enum.ActionType.WhiteList,
  },
  {
    content: 'Hide Payment',
    id: '3',
  },
  {
    content: 'Hide Shipping',
    id: '4',
  },
];

const configRules: Array<{
  id: string;
  description: string;
  src: string;
  hiddenBlockAccess?: boolean;
}> = [
    {
      id: Enum.CriteriaType.IpAddress,
      description: 'IP address',
      src: images.block_ip_address,
    },
    {
      id: Enum.CriteriaType.IpAddressStartWith,
      description: 'IP start with',
      src: images.block_ip_range,
      hiddenBlockAccess: true,
    },
    {
      id: Enum.CriteriaType.Country,
      description: 'location',
      src: images.countries,
    },
    {
      id: Enum.CriteriaType.Province,
      description: 'location',
      src: images.block_state,
      hiddenBlockAccess: true,
    },
    {
      id: Enum.CriteriaType.Collection,
      description: 'products',
      src: images.block_collection,
      hiddenBlockAccess: true,
    },
    {
      id: Enum.CriteriaType.Product,
      description: 'products',
      src: images.products,
      hiddenBlockAccess: true,
    },
    {
      id: Enum.CriteriaType.UserAgent,
      description: 'user agent',
      src: images.userAgent,
    },
    {
      id: Enum.CriteriaType.IpRanges,
      description: 'IP ranges',
      src: images.block_ip_ranges,
      hiddenBlockAccess: true,
    },
    {
      id: Enum.CriteriaType.SpecificPage,
      description: 'specific page',
      src: images.block_specific_page,
      hiddenBlockAccess: true,
    },
    {
      id: Enum.CriteriaType.ISP,
      description: 'ISP',
      src: images.block_isp,
    },
    {
      id: Enum.CriteriaType.ReferralLink,
      description: 'referrer URL',
      src: images.referralLink,
      hiddenBlockAccess: true,
    },
  ];

export const ipOptionsAddress = [
  {
    label: 'IP address',
    value: CriteriaType.IpAddress,
  },
  {
    label: 'IP start with',
    value: CriteriaType.IpAddressStartWith,
  },
  {
    label: 'IP ranges',
    value: CriteriaType.IpRanges,
  },
];
export const ipOptionsAddressFree = [
  {
    label: 'IP address',
    value: CriteriaType.IpAddress,
  },
  {
    label: 'IP start with',
    value: CriteriaType.IpAddressStartWith,
  },
];

export const ipOptionsLocation = [
  {
    label: 'IP Country',
    value: CriteriaType.Country,
  },
  {
    label: 'IP Province',
    value: CriteriaType.Province,
  },
];

export const optionsCollectionAndProduct = [
  {
    label: 'Collection',
    value: CriteriaType.Collection,
  },
  {
    label: 'Specific product',
    value: CriteriaType.Product,
  },
];
// HIDE PAYMENT
const criteriaHidePaymentFilter = [
  {
    label: 'Day of week',
    value: Enum.CriteriaHidePayment.DayOfWeek,
  },
  {
    label: 'New customer',
    value: Enum.CriteriaHidePayment.NewCustomer,
  },
  {
    label: 'Repeat customer',
    value: Enum.CriteriaHidePayment.RepeatCustomer,
  },
  // {
  //   label: 'Customer total spent',
  //   value: Enum.CriteriaHidePayment.CustomerTotalSpent,
  // },
  {
    label: 'Customer tags',
    value: Enum.CriteriaHidePayment.CustomerTags,
  },
  {
    label: 'Email',
    value: Enum.CriteriaHidePayment.Email,
  },
  {
    label: 'Email contain',
    value: Enum.CriteriaHidePayment.EmailDomain,
  },
  {
    label: 'Phone number',
    value: Enum.CriteriaHidePayment.Phone,
  },
  {
    label: 'Country',
    value: Enum.CriteriaHidePayment.Country,
  },
  // {
  //   label: 'State',
  //   value: Enum.CriteriaHidePayment.State,
  // },
  // {
  //   label: 'City',
  //   value: Enum.CriteriaHidePayment.City,
  // },
  {
    label: 'Zip code',
    value: Enum.CriteriaHidePayment.ZipCode,
  },
];

const configRulesPayment: Array<{
  id: string;
  label: string;
  title: string;
  src: string;
  condition: ConditionHidePayment;
  tooltip?: string;
}> = [
    {
      id: Enum.CriteriaHidePayment.DayOfWeek,
      label: 'Day of the week',
      title: 'Day of the week',
      src: images.hp_date_time,
      condition: ConditionHidePayment.DayOfTheWeek,
    },
    {
      id: Enum.CriteriaHidePayment.NewCustomer,
      label: 'Customer',
      title: 'New customer',
      src: images.hp_customer,
      condition: ConditionHidePayment.Customer,
    },
    {
      id: Enum.CriteriaHidePayment.RepeatCustomer,
      label: 'Customer',
      title: 'Repeat customer',
      src: images.hp_customer,
      condition: ConditionHidePayment.Customer,
    },
    {
      id: Enum.CriteriaHidePayment.CustomerTags,
      label: 'Customer',
      title: 'Customer tags',
      src: images.hp_customer,
      condition: ConditionHidePayment.Customer,
    },
    {
      id: Enum.CriteriaHidePayment.Email,
      label: 'Contact',
      title: 'Email',
      src: images.hp_contact,
      condition: ConditionHidePayment.Contact,
    },
    {
      id: Enum.CriteriaHidePayment.EmailDomain,
      label: 'Contact',
      title: 'Email contain',
      src: images.hp_contact,
      condition: ConditionHidePayment.Contact,
    },
    {
      id: Enum.CriteriaHidePayment.Phone,
      label: 'Contact',
      title: 'Phone',
      src: images.hp_contact,
      condition: ConditionHidePayment.Contact,
    },
    {
      id: Enum.CriteriaHidePayment.Country,
      label: 'Address',
      title: 'Country',
      src: images.hp_shipping_address,
      condition: ConditionHidePayment.Address,
    },
    {
      id: Enum.CriteriaHidePayment.ZipCode,
      label: 'Address',
      title: 'Zip code',
      src: images.hp_shipping_address,
      condition: ConditionHidePayment.Address,
    },
  ];

export const weekOption = [
  {
    label: 'Monday',
    value: DayType.monday,
  },
  {
    label: 'Tuesday',
    value: DayType.tuesday,
  },
  {
    label: 'Wednesday',
    value: DayType.wednesday,
  },
  {
    label: 'Thursday',
    value: DayType.thursday,
  },
  {
    label: 'Friday',
    value: DayType.friday,
  },
  {
    label: 'Saturday',
    value: DayType.saturday,
  },
  {
    label: 'Sunday',
    value: DayType.sunday,
  },
];

export const customer = [
  {
    label: 'New customer',
    value: CriteriaHidePayment.NewCustomer,
  },
  {
    label: 'Repeat customer',
    value: CriteriaHidePayment.RepeatCustomer,
  },
  {
    label: 'Customer tags',
    value: CriteriaHidePayment.CustomerTags,
  },
];

export const contact = [
  {
    label: 'Email',
    value: CriteriaHidePayment.Email,
  },
  {
    label: 'Email contain',
    value: CriteriaHidePayment.EmailDomain,
  },
  {
    label: 'Phone number',
    value: CriteriaHidePayment.Phone,
  },
];

export const nameType = [
  {
    label: 'First name',
    value: NameType.FirstName,
  },
  {
    label: 'Last name',
    value: NameType.LastName,
  },
  {
    label: 'Full name',
    value: NameType.FullName,
  },
];

export const shippingAddress = [
  {
    label: 'Country',
    value: CriteriaHidePayment.Country,
  },
  // {
  //   label: "State",
  //   value: CriteriaHidePayment.State
  // },
  // {
  //   label: "City",
  //   value: CriteriaHidePayment.City
  // },
  {
    label: 'Zip code',
    value: CriteriaHidePayment.ZipCode,
  },
];

export const conditionToCriteriaMapping = {
  [ConditionHidePayment.DayOfTheWeek]: CriteriaHidePayment.DayOfWeek,
  [ConditionHidePayment.Customer]: CriteriaHidePayment.NewCustomer,
  [ConditionHidePayment.Contact]: CriteriaHidePayment.Email,
  [ConditionHidePayment.Address]: CriteriaHidePayment.Country,
};

export const criteriaToConditionMapping = {
  [CriteriaHidePayment.DayOfWeek]: ConditionHidePayment.DayOfTheWeek,
  [CriteriaHidePayment.NewCustomer]: ConditionHidePayment.Customer,
  [CriteriaHidePayment.RepeatCustomer]: ConditionHidePayment.Customer,
  [CriteriaHidePayment.CustomerTags]: ConditionHidePayment.Customer,
  [CriteriaHidePayment.Email]: ConditionHidePayment.Contact,
  [CriteriaHidePayment.EmailDomain]: ConditionHidePayment.Contact,
  [CriteriaHidePayment.Phone]: ConditionHidePayment.Contact,
  [CriteriaHidePayment.Country]: ConditionHidePayment.Address,
  [CriteriaHidePayment.CustomerTotalSpent]: ConditionHidePayment.Customer,
  [CriteriaHidePayment.ZipCode]: ConditionHidePayment.Address,
  [CriteriaHidePayment.State]: ConditionHidePayment.Address,
  [CriteriaHidePayment.City]: ConditionHidePayment.Address,
};

export default {
  configRules,
  criteriaFilters,
  blockTypeOptions,
  typeList,
  perPageOptions,
  listTypeOptions,
  ipOptionsAddress,
  ipOptionsAddressFree,
  ipOptionsLocation,
  optionsCollectionAndProduct,
  configRulesPayment,
  weekOption,
  customer,
  shippingAddress,
  criteriaHidePaymentFilter,
  conditionToCriteriaMapping,
  criteriaToConditionMapping,
  criteriaFiltersBlockCheckout,
  nameType,
};
