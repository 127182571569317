import { images } from '@/asset';
import { CheckoutRulesBannerStyled } from './styled';
import { Icon } from '@shopify/polaris';
import { CancelMajor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import bannerSlice, { checkoutRulesBannerSelector } from '@/redux/slice/banner.slice';
import { CSSProperties } from 'react';
import { gaFourSelector } from '@/redux/slice/session.slice';
import { useGa4 } from '@/hooks/useGa4';
import { Ga4Event } from '@/constants/enum';
const CheckoutRulesBanner = (style: CSSProperties | undefined) => {
  const isVisible = useSelector(checkoutRulesBannerSelector);
  const dispatch = useDispatch();
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  return isVisible ? (
    <CheckoutRulesBannerStyled
      style={style}
      onClick={() => {
        if (!gaFour.checkoutRules) {
          handleGa4(Ga4Event.CheckoutRulesClick);
        }
        window.open('https://apps.shopify.com/blockify-checkout-rules', '_blank');
      }}
    >
      <img src={images.checkoutRulesBanner} alt="checkout-rules-banner" />
      <div
        className="btn-hide-banner"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(bannerSlice.actions.handleCheckoutRulesBanner(false));
        }}
      >
        <Icon source={CancelMajor} tone="base" />
      </div>
    </CheckoutRulesBannerStyled>
  ) : null;
};

export default CheckoutRulesBanner;
