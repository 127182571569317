import { Tooltip, Text } from '@shopify/polaris'

function RenderContentWithTooltip({ content }: { content: string | undefined }) {
  return (
    <Tooltip content={content}>
      <Text as="span" variant="bodyMd" truncate>{content}</Text>
    </Tooltip>
  )
}

export default RenderContentWithTooltip