import { Card, Tabs } from '@shopify/polaris';
import { memo, useCallback, useState } from 'react';
import HeatMapBlocked from './Blocked';
import HeatMapVisited from './Visited';
import { HeatMapStyled } from './styled';
const HeatMap = () => {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'visited',
      content: 'Allowed visitor heatmap',
      component: <HeatMapVisited />,
    },
    {
      id: 'blocked',
      content: 'Blocked visitor heatmap',
      component: <HeatMapBlocked />,
    },
  ];
  return (
    <HeatMapStyled>
      <Card padding={'100'}>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].component}
        </Tabs>
      </Card>
    </HeatMapStyled>
  );
};
export default memo(HeatMap);
