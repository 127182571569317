import { images } from '@/asset';
import { config } from '@/config';
import { PATH } from '@/constants';
import customersList from '@/constants/customerHidePayment';
import { isDevelopmentPlan } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import bannerSlice, { referCheckoutRulesBannerSelector } from '@/redux/slice/banner.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import welcomeSlice from '@/redux/slice/welcome.slice';
import { ILayout } from '@/types/components';
import { Banner, Button, ButtonGroup, Collapsible, Link, Page } from '@shopify/polaris';
import { AnalyticsMajor, PlanMajor, SecureMajor, SettingsMajor, ToolsMajor } from '@shopify/polaris-icons';
import { memo, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RegularText from '../RegularText';
import { LayoutStyled } from './styled';

const btnGroup = [
  {
    content: 'Home',
    icon: SecureMajor,
    path: PATH.DEFAULT,
  },
  {
    content: 'Visitor Analytics',
    icon: AnalyticsMajor,
    path: PATH.ANALYTICS,
  },
  {
    content: 'Blocker & Re-director ',
    icon: ToolsMajor,
    path: PATH.BLOCKLIST,
  },
  {
    content: 'Fraud orders analytics',
    icon: ToolsMajor,
    path: PATH.FRAUD_ORDER,
  },
  {
    content: 'Block checkout',
    icon: ToolsMajor,
    path: PATH.BLOCKED_CHECKOUT,
  },

  customersList.includes(config.shop)
    ? {
      content: 'Checkout rules',
      icon: ToolsMajor,
      path: PATH.CHECKOUT_RULES,
    }
    : undefined,

  {
    content: 'Settings',
    icon: SettingsMajor,
    path: PATH.SETTINGS,
  },
  {
    content: 'Feedback',
    icon: SettingsMajor,
    path: PATH.FEEDBACK,
  },
  {
    content: 'Pricing Plan',
    icon: PlanMajor,
    path: PATH.PRICING_PLAN,
  },
  {
    content: 'Development',
    icon: PlanMajor,
    path: PATH.CONTACT_TO_USE_DEVELOPMENT_STORE,
    hide: true,
  },
];

const Layout = ({ children, layoutProps, isVisibleHeader = true }: ILayout.IProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const dataSettings = useSelector(dataSettingsSelector);
  const isDevPlan = dataSettings && isDevelopmentPlan(dataSettings?.settings.shopifyPlan);
  const { userPlanFree } = userPlans();
  const bannerReferCheckoutRules = useSelector(referCheckoutRulesBannerSelector);
  const [trackAction] = apiCaller.useTrackActionMutation();
  const handleRedirectToCheckoutRuleApp = () => {
    window.open('https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=blockify&utm_medium=dashboardinapp', '_blank');
    trackAction('banner-set-up-checkout-rule')
  };

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (userPlanFree && isDevPlan) {
      navigate(PATH.CONTACT_TO_USE_DEVELOPMENT_STORE);
    }
  }, [isDevPlan, userPlanFree, navigate]);

  useLayoutEffect(() => {
    if (location.pathname === PATH.CONTACT_TO_USE_DEVELOPMENT_STORE.pathname && !isDevPlan) {
      navigate(PATH.DEFAULT);
    }
  }, [location.pathname, navigate, isDevPlan]);
  useEffect(() => {
    if (dataSettings && dataSettings.settings) {
      if (userPlanFree && isDevPlan) {
        navigate(PATH.CONTACT_TO_USE_DEVELOPMENT_STORE);
      } else {
        if (dataSettings.settings.displayWelcome) {
          dispatch(welcomeSlice.actions.handleChangeWelcomeStep(dataSettings.settings.welcomeStep));
          navigate(PATH.WELCOME);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSettings)]);
  const [showBannerReferApp, setShowBannerReferApp] = useState(false);
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (bannerReferCheckoutRules && location.pathname === PATH.DEFAULT.pathname) {
        setShowBannerReferApp(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [bannerReferCheckoutRules, location.pathname]);

  return (
    <LayoutStyled>
      {isVisibleHeader && config.embedded !== '1' ? (
        <div className="nav-bar">
          <ButtonGroup fullWidth gap="extraTight">
            {btnGroup
              .filter((item) => item !== undefined)
              .filter((item) => item?.hide !== true)
              .filter((item) => item?.path !== PATH.HIDE_PAYMENT)
              .map((item, index) => {
                return (
                  <Button
                    key={index}
                    icon={item?.icon}
                    variant={
                      location.pathname === item?.path.pathname || (index === 0 && location.pathname === '/')
                        ? 'primary'
                        : undefined
                    }
                    onClick={() => {
                      navigate({
                        ...item?.path,
                      });
                    }}
                  >
                    {item?.content}
                  </Button>
                );
              })}
          </ButtonGroup>
        </div>
      ) : null}
      <Collapsible
        id="banner-check-refer-app"
        open={showBannerReferApp}
        transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
      >
        <div className='banner-refer-app'>
          <Banner
            onDismiss={() => {
              dispatch(bannerSlice.actions.handleReferCheckoutRulesBanner(false));
              setShowBannerReferApp(false);
            }}
            tone="info"
            title=""
            hideIcon
          >
            <div className='d-flex banner-refer-app-content'>
              <img className='banner-refer-app-logo' src={images.checkoutRulesLogo} alt='banner-refer-checkout-rule'></img>
              <RegularText>
                Hide payment methods with Blockify: Checkout Rules. Seamlessly checkout experience and validate if customer missing house number.
              </RegularText>
              <div className='ml-4 btn-set-up'>
                <Button target="_blank" onClick={handleRedirectToCheckoutRuleApp}>
                  Set up now
                </Button>
              </div>
            </div>
          </Banner>
        </div>
      </Collapsible>

      <Page {...layoutProps}>
        <div>{children}</div>
        <RegularText>
          <div className="layout-footer">
            Need help? Please view&nbsp;{' '}
            <Link removeUnderline external url="https://docs.ipblocker.io/">
              our document guideline
            </Link>
          </div>
        </RegularText>
      </Page>
    </LayoutStyled>
  );
};
export default memo(Layout);
