import { Enum, PATH } from '@/constants';
import { apiCaller } from '@/redux/query';
import { Banner, Link } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import RegularText from '../RegularText';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';

interface Props {
  isHasLink?: boolean;
  onDismiss: () => void;
  mixpanelName?: any;
}

function BannerLimitRules({ isHasLink, onDismiss, mixpanelName }: Props) {
  const navigate = useNavigate();
  const dataSettings = useSelector(dataSettingsSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery({
    type: Enum.ActionType.Block,
    priority: Enum.ListType.BlackList,
    criteria: Enum.CriteriaType.IpAddress,
  });
  return (
    <Banner
      title={
        (ruleBlockSummary?.data?.totalRulesActivated || 0) >= maxLimitRules ? 'You have reached the limitation of the Free plan.' : ''
      }
      tone={(ruleBlockSummary?.data?.totalRulesActivated || 0) >= maxLimitRules ? 'warning' : 'info'}
      action={{
        content: ruleBlockSummary?.data?.totalRulesActivated === 0 ? 'See pricing plan' : 'Increase limit',
        onAction: () => {
          navigate(PATH.PRICING_PLAN);
          mixpanel?.track(mixpanelName);
        },
      }}
      onDismiss={onDismiss}
    >
      {ruleBlockSummary?.data?.totalRulesActivated === 0 ?
        <RegularText>
          You are currently in <b>Free plan</b>. Upgrade to get unlimited access of Blockify. {
            isHasLink && <Link onClick={() => navigate(PATH.PRICING_PLAN)}>Learn more.</Link>
          }
        </RegularText>
        :
        <RegularText>
          {(ruleBlockSummary?.data?.totalRulesActivated || 0) >= maxLimitRules ? (
            <>
              <b>{`${ruleBlockSummary?.data?.totalRulesActivated || 0}/${maxLimitRules}`}</b> active rules of the Free plan. Increase the
              limit to add more rules. {
                isHasLink && <Link onClick={() => navigate(PATH.PRICING_PLAN)}>Learn more.</Link>
              }
            </>
          ) : (
            <>
              <b>{`${ruleBlockSummary?.data?.totalRulesActivated || 0}/${maxLimitRules}`}</b> active rules of the Free plan. You nearly
              reach the limitation of Free plan. Increase the limit to add more rules. {
                isHasLink && <Link onClick={() => navigate(PATH.PRICING_PLAN)}>Learn more.</Link>
              }
            </>
          )}
        </RegularText>
      }
    </Banner>
  )
}

export default BannerLimitRules